import { Container, Row, Col, Card } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { Button } from "./Controls/Button";
import { useState } from "react";
import { AddLineItemModal, LineItem } from "./FacilityOneTimeInvoiceCreatePageAddLineItem";
import { AddRecipientModal, RecipientForm } from "./FacilityOneTimeInvoiceCreatePageAddRecipientModal";
import { showAddressHtml } from "./Data/Location";
import React from "react";
import { Input } from "./Controls/Input";
import { OperatorApi } from "./Data/ApiTransport";

export function FacilityInvoiceEditPage() {
    const operator: OperatorApi = {
        operatorId: 0,
        name: "",
        address: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: ""
        },
        emailAddress: "",
        phoneNumber: "",
        website: "",
        topTitle: "",
        topText: "",
        bottomLeftTitle: "",
        bottomLeftText: "",
        bottomRightTitle: "",
        bottomRightText: ""
    };
    const [showAddRec, setShowAddRec  ] = useState( false );
    const [showAddItem, setShowAddItem] = useState( false );
    const [recipients, setRecipients] = useState<RecipientForm[]>( [] );
    const [items, setItems] = useState<LineItem[]>( [] );

    function addRecipient( r: RecipientForm ) {
        setRecipients( [ ...recipients, r ] );
    }

    function addItem( item: LineItem ) {
        setItems( [ ...items, item ] );
    }

    return <Container fluid>
        <AddRecipientModal show={showAddRec}  setShow={setShowAddRec}  onSave={addRecipient} />
        <AddLineItemModal  show={showAddItem} setShow={setShowAddItem} onSave={addItem} />
        <Row>
            <Col>
                <div className="d-flex gap-2">
                    <PageTitle className="flex-grow-1">
                        Create New One-Time Invoice
                    </PageTitle>
                    <div className="d-flex flex-grow-1 gap-2 justify-content-end">
                        <Button onClick={ () => setShowAddRec( true )}>Add Recipient</Button>
                        <Button onClick={ () => setShowAddItem( true )}>Add Line Item</Button>
                    </div>
                </div>
                <Card className="mt-2">
                    <Card.Header>Invoice Preview</Card.Header>
                    <Card.Body>
                        <pre>
                            {operator.name}
                            {showAddressHtml( operator.address )}
                        </pre>
                        <div className="d-flex align-items-center">
                            <div>
                                To: &nbsp;
                                {recipients.length === 0 ? "No Recipients Added" : recipients.map( r => <>{r.firstName} {r.lastName}</>)}
                            </div>
                        </div>
                        <div className="d-grid mt-2">
                            <div className="grid-col-1 border-bottom border-2">Product or Service</div>
                            <div className="grid-col-2 border-bottom border-2">Category</div>
                            <div className="grid-col-3 border-bottom border-2 text-end">Unit Price</div>
                            <div className="grid-col-4 border-bottom border-2 text-end">Quantity</div>
                            {items.length === 0 && "No Items added yet"}
                            {items.map( (el, i) => <React.Fragment key={i}>
                                <div className="grid-col-1">{el.desc}</div>
                                <div className="grid-col-2">{el.cat}</div>
                                <div className="grid-col-3 text-end">{el.unitPrice}</div>
                                <div className="grid-col-4 text-end">{el.qty}</div>
                            </React.Fragment>)}
                        </div>
                        <div className="mt-2">
                            <Input type="text" as="textarea" label="Notes" rows={7} />
                        </div>
                    </Card.Body>
                    <Card.Footer className="d-flex gap-2">
                        <Button className="flex-grow-1 border-danger">Discard Invoice</Button>
                        <Button className="flex-grow-1">Send Invoice</Button>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </Container>
}