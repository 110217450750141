import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

//spotsync controls
import { AddressModal, AddressAndEntry } from "./FacilityOverviewPageAddressModal";
import { CapacityModal, CapacityForm } from "./FacilityOverviewPageCapacityModal";
import { Hyperlink } from "./Controls/Hyperlink";

//spotsync data
import { showAddressFunc } from "./Data/Location";
import { showMoneyShort } from "./Data/Money";
import { PageTitle } from "./Controls/PageTitle";
import NameDescriptionModal from "./FacilityOverviewPageNameDescriptionModal";
import { NameAndDescription } from "./FacilityOverviewPageNameDescriptionModal";

import { FacilityPhotos } from "./Controls/FacilityPhotos";
import { useRouteLoaderData } from "react-router-dom";
import { Facility, FacilityUpdateReq } from "./Data/ApiTypes";
import { Api } from "./Data/Api";

export function FacilityListingPage() {
    const [showNameAndDesc, setShowNameAndDesc] = useState( false );
    const [showAddress,     setShowAddress]     = useState( false );
    const [showCapacity,    setShowCapacity]    = useState( false );
    const facility = useRouteLoaderData("facility") as Facility;

    const handleNameSave = (data: NameAndDescription) => {
        facility.name = data.name;
        facility.description = data.description;
        saveFacilityUpdates();
    }

    const handleAddressEntrySave = (data: AddressAndEntry) => {
        facility.address.addressLine1 = data.addressLine1;
        facility.address.addressLine2 = data.addressLine2;
        facility.address.city = data.city;
        facility.address.state = data.state;
        facility.address.zipCode = data.zipCode;
        facility.description = data.entry;
        saveFacilityUpdates();
    }

    const handleCapacitySave = (data: CapacityForm) => {
        facility.enforcedCapacity = data.enforcedCapacity;
        facility.physicalCapacity = data.postedCapacity;
        saveFacilityUpdates();
    }

    const saveFacilityUpdates = () => {
        const updateReq : FacilityUpdateReq = {
             address: facility.address,
             facilityId: facility.facilityId,
             instructions: facility.entryInstructions,
             description: facility.description,
             name:facility.name,
             enforcedCapacity: Number(facility.enforcedCapacity),
             postedCapacity: Number(facility.physicalCapacity)
        }

        Api.facilityUpdate( updateReq ).then( res => {
            //TODO: implement some type of global toast for success and failure scenarios
            if( res.isOk ) {
                console.log('Updated Successfully');
            }
            else{
                console.log(res.error);
            }
        } );
    }

    return <Container fluid>
        <NameDescriptionModal show={showNameAndDesc}
                              setShow={setShowNameAndDesc}
                              onSave={ handleNameSave }
                              initialData={{ name: facility.name, description: facility.description }} />

        <AddressModal  address={facility.address}
                       entry={facility.entryInstructions}
                       show={showAddress}
                       setShow={setShowAddress}
                       onSave={ handleAddressEntrySave } />

        <CapacityModal show={showCapacity}
                       postedCapacity={facility.physicalCapacity}
                       enforcedCapacity={facility.enforcedCapacity}
                       setShow={setShowCapacity}
                       onSave={ handleCapacitySave } />
        <Row>
            <Col>
                <div className="grid-col-span-2"
                     style={{ maxHeight: "10em" }}>
                        <FacilityPhotos
                            imgs={facility.images} />
                </div>
                <PageTitle>
                    {facility.name}
                </PageTitle>
                <div className="mt-2 gap-2"
                    style={{
                        display:             "grid",
                        gridTemplateColumns: "max-content minmax( min-content, max-content )",
                        gridTemplateRows:    "repeat( auto )"
                    }}>
                    <div className="grid-col-1 fs-5 fw-bold">
                        Name
                    </div>
                    <div className="grid-col-2 d-flex align-items-center">
                        <Hyperlink  onClick={ () => setShowNameAndDesc( true ) }>
                            Edit
                        </Hyperlink>
                    </div>
                    <div className="grid-col-1 text-secondary">
                        Name
                    </div>
                    <div className="grid-col-2">
                        {facility.name}
                    </div>
                    <div className="grid-col-1 text-secondary">
                        Description
                    </div>
                    <div className="grid-col-2">
                        {facility.description}
                    </div>
                    <div className="grid-col-1 fs-5 fw-bold">
                        Location
                    </div>
                    <div className="grid-col-2 d-flex align-items-center">
                        <Hyperlink  onClick={ () => setShowAddress( true ) }>
                            Edit
                        </Hyperlink>
                    </div>
                    {showAddressFunc( facility.address, ( s, c, st, z ) => ( [
                        ["Street Address", s],
                        ["City",           c],
                        ["State",         st],
                        ["Zip Code",       z]
                    ] ).map( pair => <>
                        <div className="grid-col-1 text-secondary">{pair[0]}</div>
                        <div className="grid-col-2">{pair[1]}</div>
                    </> ) )}
                    <div className="grid-col-1 text-secondary">
                        Entry Instructions
                    </div>
                    <div className="grid-col-2">
                        {facility.entryInstructions}
                    </div>

                    <div className="grid-col-span-1 fs-5 fw-bold">
                        Capacity
                    </div>
                    <div className="grid-col-2 d-flex align-items-center">
                        <Hyperlink onClick={ () => setShowCapacity( true )}>
                            Edit
                        </Hyperlink>
                    </div>
                    <div className="grid-col-1 text-secondary">
                        Posted Capacity
                    </div>
                    <div className="grid-col-2">
                        {facility.physicalCapacity}
                    </div>
                    <div className="grid-col-1 text-secondary">
                        Enforced Capacity
                    </div>
                    <div className="grid-col-2">
                        {facility.enforcedCapacity}
                    </div>
                    <div className="grid-col-1 grid-col-span-1 fs-5 fw-bold">
                        Monthly Plans
                    </div>
                    <div className="grid-col-2 d-flex align-items-center">
                        <Hyperlink to={`/facility/${facility.facilityId}/plan`}>
                            Manage
                        </Hyperlink>
                    </div>
                    {facility.listedPlans.map( plan => <React.Fragment key={plan.planEntryId}>
                        <div className="grid-col-1">
                            {plan.name}
                        </div>
                        <div className="grid-col-2">
                            {showMoneyShort( plan.price )}
                        </div>
                    </React.Fragment> )}
                    <div className="grid-col-1 grid-col-span-1 fs-5 fw-bold">
                        Hourly Rates
                    </div>
                    <div className="grid-col-2 d-flex align-items-center">
                        <Hyperlink to={`/facility/${facility.facilityId}/rate`}>
                            Manage
                        </Hyperlink>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>;
}
