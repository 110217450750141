import React, { useMemo, useState } from 'react';
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useLoaderData } from "react-router-dom";
import { Payment } from "./Data/ApiTypes";
import { showMoney } from "./Data/Money";
import { showPaymentType } from "./Data/Finance";
import { PageTitle } from "./Controls/PageTitle";
import { Hyperlink } from "./Controls/Hyperlink";
import { CheckFormModal } from "./FacilityParkerPaymentListPageAddCheckModal";
import { useParamIds } from "./Data/Common";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parsePayment } from "./Data/ApiParse";

export function FacilityParkerPaymentListPage() {
    const { facilityId, parkerId } = useParamIds();
    const [showAddCheck, setShowAddCheck] = useState( false );
    const loadedPayments = useLoaderData() as Payment[];
    const [payments, setPayments] = useState( loadedPayments );

    const defaultColDef = useMemo<ColDef>( () => ( {
        sortable:  true,
        filter:    true,
        resizable: true,
    } ), []);

    const paymentColumns = useMemo<ColDef<Payment>[]>( () => [
        { field: 'type',        headerName: 'Type', valueFormatter: x => showPaymentType( x.value ) },
        { field: 'paymentId',   headerName: 'Payment #',    type: 'rightAligned' },
        { field: 'amount',      headerName: 'Amount',       type: 'rightAligned', valueFormatter: x => showMoney( x.value ) },
        { field: 'balance',     headerName: 'Remaining',    type: 'rightAligned', valueFormatter: x  => showMoney( x.value ) },
        { field: 'createdWhen', headerName: 'Created Date', type: 'rightAligned' },
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <Container>
            <CheckFormModal
                show={showAddCheck}
                setShow={setShowAddCheck}
                facilityId={facilityId}
                parkerId={parkerId}
                onSave={ (data) => {
                    Api.paymentCheckCreate( facilityId, parkerId, data ).then(
                        res => processResult( res,
                            val => setPayments( [...payments, parsePayment( val ) ] ) ),
                            err => console.log( err )
                        );
                } } />
            <Row>
                <Col>
                    <PageTitle>
                        Payments
                    </PageTitle>
                    <div>
                        <Hyperlink onClick={ () => setShowAddCheck( true )}>
                            Add Check Payment
                        </Hyperlink>
                    </div>
                    <Tabs
                        defaultActiveKey="payments"
                        id="parker-details-tabs"
                        className="mb-3 mt-4"
                        variant="underline" justify>
                        <Tab eventKey="payments" title="Payments">
                            <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                                <AgGridReact
                                    rowData={payments}
                                    columnDefs={paymentColumns}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    domLayout='autoHeight'
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}