import React from "react";

export interface HeaderTextProps {
    size?:      number;
    className?: string;
    children?:  React.ReactNode;
}

export function HeaderText( props: HeaderTextProps ) {
    const {
        size      = 4,
        className = "",
        children  = <></>
    } = props;
    return <div className={`${className} fs-${size} fw-bold`}>
        {children}
    </div>;
}
