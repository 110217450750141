import React, { useMemo, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { cents, showMoney, showMoneyShort } from "./Data/Money";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useLoaderData, useNavigate } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { Hyperlink } from "./Controls/Hyperlink";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ParkerListEntry } from "./Data/ApiTypes";

enum MonthlyParkerTab {
    Applied = "pending",
    Active  = "active",
    Invited = "invited"
}

export function FacilityParkerListPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const [tab, setTab]  = useState<MonthlyParkerTab>( MonthlyParkerTab.Active );
    const parkers        = useLoaderData() as ParkerListEntry[];
    const activeParkers  = parkers;
    const defaultColDef  = useMemo<ColDef>( () => ( {
        sortable: true,
        filter:   true,
    } ), [] );

    const activeColumns = useMemo<ColDef[]>(() => [
        { field: "parkerId", headerName: "Id"},
        { field: "name", headerName: "Name"},
        { field: "emailAddress", headerName: "Email"},
        { field: "balance",   headerName: "Balance"    , type: "rightAligned", valueFormatter: x => showMoney( x.value ) },
     // { field: "totalDebt", headerName: "Gross Receivable", type: "rightAligned", valueFormatter: x => showMoney( x.value ) },
        { field: "totalPaid", headerName: "Total Paid",  type: "rightAligned", valueFormatter: x => showMoney( x.value ) },
        { field: "note", headerName: "Note"},
    ], []);

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = (event: any) => {
        if (tab === MonthlyParkerTab.Active) {
            nav(`/facility/${facilityId}/parker/${event.data.parkerId}`);
        } else if (tab === MonthlyParkerTab.Applied) {
            nav(`/facility/${facilityId}/parker/invite/${event.data.parkerId}`);
        }
    };

    return (
        <Container fluid className="pt-2 h-100">
            <Row className="h-100">
                <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto auto 1fr 5px", gridAutoColumns: "1fr" }}>
                    <div className="d-flex align-items-center">
                        <PageTitle className="ms-2 mb-2 me-3">
                            Monthly Parkers
                        </PageTitle>
                        <Hyperlink onClick={() => nav(`/facility/${facilityId}/parker/invite`)}>
                            Invite
                        </Hyperlink>
                    </div>
                    <Tabs
                        defaultActiveKey={MonthlyParkerTab.Active}
                        activeKey={tab}
                        onSelect={(k) => setTab(k as MonthlyParkerTab)}
                        className="mb-3 w-100"
                        variant="underline"
                        justify>
                        <Tab eventKey={MonthlyParkerTab.Active} title="Active" className="h-100">
                            <div className="ag-theme-alpine" style={{height: "100%", width: '100%'}}>
                                <AgGridReact
                                    animateRows={false}
                                    rowData={activeParkers}
                                    columnDefs={activeColumns}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    onRowClicked={onRowClicked}
                                />
                            </div>
                        </Tab>
                        {/* <Tab eventKey={MonthlyParkerTab.Applied} title="Applied" className="h-100">
                            <div className="ag-theme-alpine" style={{height: "100%", width: '100%'}}>
                                <AgGridReact
                                    animateRows={false}
                                    rowData={appliedParkers}
                                    columnDefs={appliedColumns}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    onRowClicked={onRowClicked}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={MonthlyParkerTab.Invited} title="Invited" className="h-100">
                            <div className="ag-theme-alpine" style={{height: "100%", width: '100%'}}>
                                <AgGridReact
                                    animateRows={false}
                                    rowData={invitedParkers}
                                    columnDefs={invitedColumns}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                />
                            </div>
                        </Tab> */}
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}