// Theme
import { ColDef, RowSelectionOptions, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";

/* Format Date Cells */
const dateFormatter = ( params: ValueFormatterParams ): string => {
    return new Date( params.value ).toLocaleDateString( "en-us", {
        weekday: "long",
        year:    "numeric",
        month:   "short",
        day:     "numeric",
    } );
};

// Row Data Interface
interface IRow {
    mission:    string;
    company:    string;
    location:   string;
    date:       string;
    time:       string;
    rocket:     string;
    price:      number;
    successful: boolean;
}

const rowSelection: RowSelectionOptions = {
    mode:           "multiRow",
    headerCheckbox: false,
};

// Create new GridExample component
export const Sandbox = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState<IRow[]>( [] );

    // Column Definitions: Defines & controls grid columns.
    const [colDefs] = useState<ColDef[]>( [{
            field: "mission",
            width: 150,
        },
        {
            field: "company",
            width: 130,
        },
        {
            field: "location",
            width: 225,
        },
        {
            field: "date",
            valueFormatter: dateFormatter,
        },
        {
            field: "price",
            width: 130,
            valueFormatter: ( params: ValueFormatterParams ) => {
                return "£" + params.value.toLocaleString();
            },
        },
        {
            field: "successful",
            width: 120,
            // cellRenderer: MissionResultRenderer,
        },
        { field: "rocket" },
    ] );

    // Fetch data & update rowData state
    useEffect( () => {
        fetch( "https://www.ag-grid.com/example-assets/space-mission-data.json" )
            .then( ( result ) => result.json() )
            .then( ( rowData ) => setRowData( rowData ) );
    }, [] );

    // Apply settings across all columns
    const defaultColDef = useMemo<ColDef>( () => {
        return {
            filter: true,
            editable: true,
        };
    }, [] );

    // Container: Defines the grid's theme & dimensions.
    return (
        <div
            className={"ag-theme-quartz"}
            style={{ width: "100%", height: "100vh" }}>
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                rowSelection={rowSelection}
                onSelectionChanged={( event ) => console.log( "Row Selected!" )}
                onCellValueChanged={( event ) =>
                    console.log( `New Cell Value: ${event.value}` )
                }
            />
        </div>
    );
};