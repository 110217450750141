import { ReactNode } from "react";
import { Col, ColProps, Container, Row } from "react-bootstrap";

export function CenteredPage( props: ColProps ) {
    const { children, style = {}, className = "", ...rest } = props;
    return <Container fluid
    className="fullheight">
    <Row className="fullheight justify-content-center">
        <Col {...rest}
             className={`${className} fullheight d-flex align-items-center`}
             style={{ ...style, position: "relative", top: "-2%" }}>
                {children}
            </Col>
        </Row>
    </Container>;
}