import { Card, Col, Container, Form, ListGroup, Row, Alert } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { useState } from "react";
import { useMatch } from "react-router-dom";
import ImageUploader from "./Controls/ImageUploader";
import { PageTitle } from "./Controls/PageTitle";
import { FacilityHourlyDetails } from "./Controls/FacilityHourlyDetails";
import { HeaderText } from "./Controls/HeaderText";
import { ResultHourlyEntryPreview } from "./Controls/ResultHourlyEntryPreview";
import { FacilityCreateReq } from "./Data/ApiTypes";
import { Api } from "./Data/Api";
import { Button } from "./Controls/Button";
import { useNavigate } from "react-router-dom";

type FacilityCreateInputs = {
    name:              string;
    streetAddress:     string;
    city:              string;
    state:             string;
    zipCode:           string;
    entryInstructions: string;
    description:       string;
    postedCapacity:    number;
    enforcedCapacity:  number;
};

export function FacilityEditPage() {
    const createMode = useMatch( "/facility/create" ) !== null;
    const topLabel   = createMode ? "Create Facility" : "Edit Facility";

    const [thumbnailFile, setThumbnailFile] = useState<File>();
    const [thumbnailPrev, setThumbnailPrev] = useState<string>();
    const [imageMissingMsg, setImageMissingMsg] = useState<string>();

    const [bannerFile, setBannerFile  ] = useState<File>  ();
    const [bannerPrev, setBannerPrev  ] = useState<string>();
    const nav   = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FacilityCreateInputs>( { defaultValues: {
        name:              "",
        streetAddress:     "",
        city:              "",
        state:             "",
        zipCode:           "",
        entryInstructions: "",
        description:       "",
        enforcedCapacity:  0,
        postedCapacity:    0,
    } } );

    async function fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // remove the data:image prefix so we can decode on server
                const regex = /^data:image\/[a-zA-Z]+;base64,/;
                resolve((reader.result as string).replace(regex, ''));
            }
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const onSubmit: SubmitHandler<FacilityCreateInputs> = async ( data ) => {
        //TODO: add loading dialog during save
        debugger;

        //validate images are present
        if( !bannerFile || !thumbnailFile ){
            setImageMissingMsg('Please add thumbnail and banner images before saving.')
            return;
        }

        const facilityCreateReq: FacilityCreateReq = {
            name: data.name,
            address: {
                addressLine1: data.streetAddress,
                addressLine2: "",
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
            },
            instructions: data.entryInstructions,
            postedCapacity: data.postedCapacity,
            enforcedCapacity: data.enforcedCapacity,
            imageThumb: thumbnailFile ? await fileToBase64( thumbnailFile ) : '',
            bannerImage: bannerFile ? await fileToBase64( bannerFile ) : '',
            description: data.description
        };

        Api.facilityCreate( facilityCreateReq ).then( res => {
            //TODO: implement some type of global toast for success and failure scenarios
            if( res.isOk ) {
                nav( '/' );
                return;
            }
            else{
                console.log(res.error);
            }
        } );

    };

    const { name, city, state, streetAddress, zipCode, entryInstructions } = watch();

    return (
        <Container className="w-100 pt-2">
            <Row>
                <Col md={8} lg={7} xl={6} xxl={5}>
                    <PageTitle>
                        {topLabel}
                    </PageTitle>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            type="text"
                            label="Facility Name"
                            placeholder="Facility Name"
                            error={errors.name}
                            {...register( "name", { required: "Facility Name is required" } )}
                        />
                         <Input
                            as="textarea"
                            type="text"
                            label="Description"
                            placeholder=""
                            error={errors.description}
                            {...register( "description", {
                                required: "Description is required",
                            } )} />
                        <Input
                            type="text"
                            label="Street Address"
                            placeholder="Street Address"
                            error={errors.streetAddress}
                            {...register( "streetAddress", { required: "Street Address is required" } )}
                        />

                        <Input
                            type="text"
                            label="City"
                            placeholder="City"
                            error={errors.city}
                            {...register( "city", { required: "City is required" } )}
                        />

                        <div className="two-col">
                            <Input
                                type="text"
                                label="Two Letter State Code"
                                placeholder="Two Letter State Code"
                                error={errors.state}
                                maxLength={2}
                                value={watch( "state" )}
                                {...register( "state", {
                                    required: "State is required",
                                    setValueAs: ( val: string ) => val.toUpperCase(),
                                    pattern: {
                                        value: /^[A-Z]{2}$/,
                                        message: "Please enter a valid two-letter state code"
                                    }
                                } )}
                            />
                            <Input
                                type="text"
                                label="Zip Code"
                                placeholder="Zip Code"
                                error={errors.zipCode}
                                maxLength={10}
                                {...register( "zipCode", {
                                    required: "Zip Code is required",
                                    pattern: {
                                        value: /^\d{5}(-\d{4})?$/,
                                        message: "Please enter a valid zip code"
                                    }
                                } )}
                            />
                        </div>

                        <Input
                            as="textarea"
                            type="text"
                            label="Entry Instructions"
                            placeholder=""
                            error={errors.entryInstructions}
                            {...register( "entryInstructions", {
                                required: "Entry instructions are required",
                            } )} />

                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} className="gap-2">
                            <Input type="number"
                                   label="Posted Capacity"
                                   min={0}
                                   {...register( "postedCapacity", { valueAsNumber: true,
                                                                     required:      "Posted Capacity is required" } )}
                                   explanation="Number of physical parking spaces"
                                   error={errors.postedCapacity} />
                            <Input type="number"
                                   label="Enforced Capacity"
                                   min={0}
                                   {...register( "enforcedCapacity", { valueAsNumber: true,
                                                                       required:      "Enforced Capacity is required" } )}
                                   explanation="Number of spaces allowed to be sold"
                                   error={errors.enforcedCapacity} />
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} className="gap-2">
                            <ImageUploader file={thumbnailFile}
                                setFile={setThumbnailFile}
                                preview={thumbnailPrev}
                                setPreview={setThumbnailPrev}>
                                Upload Facility Thumbnail
                            </ImageUploader>
                            <ImageUploader file={bannerFile}
                                setFile={setBannerFile}
                                preview={bannerPrev}
                                setPreview={setBannerPrev}>
                                Upload Facility Banner
                            </ImageUploader>
                        </div>
                        {imageMissingMsg && <Alert variant="danger">
                                {imageMissingMsg}
                            </Alert>}
                        <div className="d-flex justify-content-end mt-4">
                            <Button type="submit">
                                Save and Create
                            </Button>
                        </div>
                    </Form>
                </Col>
                <Col md={8} lg={7} xl={6} xxl={5}>
                    <Card>
                        <Card.Header>
                            <HeaderText>Facility Details Preview</HeaderText>
                        </Card.Header>
                        <Card.Body>
                            <FacilityHourlyDetails
                                name={name}
                                image={bannerPrev}
                                entryInstructions={entryInstructions}
                                type={"Garage"} />
                        </Card.Body>
                    </Card>
                    <Card className="mt-2">
                        <Card.Header>
                            <HeaderText>Search Result Preview</HeaderText>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <div className="text-secondary pb-3">
                                This is an example of how your facility will appear in search results.
                            </div>
                            <ResultHourlyEntryPreview
                                name={name}
                                duration="12h"
                                thumbnailPrev={thumbnailPrev}
                                price="$20"
                                walkTime="10" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}