import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";

interface NameDescriptionModalProps {
    show:        boolean;
    setShow:     ( show: boolean            ) => void;
    onSave:      ( data: NameAndDescription ) => void;
    initialData: NameAndDescription;
}

export interface NameAndDescription {
    name:        string;
    description: string;
}

export default function NameDescriptionModal( { show, setShow, onSave, initialData }: NameDescriptionModalProps ) {
    const { register, formState: { errors }, handleSubmit } = useForm<NameAndDescription>( {
        defaultValues: initialData
    } );

    function submit( data: NameAndDescription ) {
        onSave( data );
        setShow( false );
    }

    return (
        <Modal show={show} centered onHide={() => setShow( false )}>
            <Form onSubmit={handleSubmit( submit )}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-semibold">Edit Name and Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <Input type="text"
                                label="Facility Name"
                                {...register( "name", { required: "Name is required" } )}
                                placeholder="Facility Name"
                                error={errors.name}
                            />
                        </div>
                        <div>
                            <Input as="textarea"
                                type="text"
                                label="Facility Description"
                                {...register( "description", { required: "Description is required" } )}
                                placeholder="Facility Description"
                                rows={4}
                                error={errors.description}
                            />
                            {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow( false )}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}