import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import { Hyperlink } from "./Controls/Hyperlink";
import { useParamIds } from "./Data/Common";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

interface Invoice {
    invoice:      string;
    firstName:    string;
    lastName:     string;
    email:        string;
    phoneNumber:  string;
    invoiceTotal: string;
    createDate:   string;
    dueDate:      string;
}

const sampleData: Invoice[] = [
    { invoice: "INV-001", firstName: "John", lastName: "Doe", email: "john.doe@example.com", phoneNumber: "(555) 123-4567", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-002", firstName: "Jane", lastName: "Smith", email: "jane.smith@example.com", phoneNumber: "(555) 234-5678", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-003", firstName: "Michael", lastName: "Johnson", email: "michael.j@example.com", phoneNumber: "(555) 345-6789", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-004", firstName: "Emily", lastName: "Brown", email: "emily.b@example.com", phoneNumber: "(555) 456-7890", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-005", firstName: "David", lastName: "Wilson", email: "david.w@example.com", phoneNumber: "(555) 567-8901", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-006", firstName: "Sarah", lastName: "Taylor", email: "sarah.t@example.com", phoneNumber: "(555) 678-9012", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
    { invoice: "INV-007", firstName: "Robert", lastName: "Anderson", email: "robert.a@example.com", phoneNumber: "(555) 789-0123", invoiceTotal: "$200.00", createDate: "08/01/2024", dueDate: "08/15/2024" },
];

const InvoiceLinkRenderer = (props: ICellRendererParams) => {
    return (
        <a href="#" onClick={(e) => { e.preventDefault(); /* Handle click event */ }}>
            {props.value}
        </a>
    );
};

export function FacilityOneTimeInvoiceListPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const defaultColDef = useMemo<ColDef>(() => ({
        sortable:  true,
        filter:    true,
        resizable: true,
    }), []);

    const columns = useMemo<ColDef[]>(
        () => [
            {
                field:        "invoice",
                headerName:   "Invoice",
                cellRenderer: InvoiceLinkRenderer,
            },
            { field: "firstName",    headerName: "First Name" },
            { field: "lastName",     headerName: "Last Name" },
            { field: "email",        headerName: "Email" },
            { field: "phoneNumber",  headerName: "Phone Number" },
            {
                field:      "invoiceTotal", 
                headerName: "Invoice Total", 
                type:       "rightAligned"
            },
            { field: "createDate",   headerName: "Create Date" },
            { field: "dueDate",      headerName: "Due Date" },
        ], []
    );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr 5px"}}>
                    <div className="d-flex align-items-center">
                        <PageTitle className="d-flex align-items-center mb-2 me-3">
                            One-Time Invoices
                        </PageTitle>
                        <Hyperlink onClick={() => nav(`/facility/${facilityId}/invoice/create`)}>
                            Create
                        </Hyperlink>
                    </div>
                    <div className="ag-theme-alpine" style={{height: "100%", width: "100%"}}>
                        <AgGridReact
                            rowData={sampleData}
                            columnDefs={columns}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            domLayout="autoHeight"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}