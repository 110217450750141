import { ReactNode, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

//spotsync controls
import { Hyperlink } from "./Controls/Hyperlink";
import { PageTitle } from "./Controls/PageTitle";
import { ContactModal } from "./FacilityParkerDetailsPageContactModal";
import { cents, Money, showMoney, toCents } from "./Data/Money";
import { add, multiply } from "dinero.js";
import { useLoaderData } from "react-router-dom";
import { ParkerDetails } from "./Data/ApiTypes";
import { showDateTime } from "./Data/Date";
import { Cond, useParamIds } from "./Data/Common";

function balanceColor( val: number ) {
    if( val <= 0 )
        return "success";
    return "warning";
}

interface BigStatProps {
    label: ReactNode;
    value: Money;
    good?: boolean;
    warn?: boolean;
    bad?:  boolean;
}

const BigStat = ( { label, value }: BigStatProps ) => {
    return <Cond show={true}>
        <div style={{ flex: "1 1 0" }}>
            <div className={`text-secondary text-center`}>
                {label}
            </div>
            <div className={`fs-4 text-center`}>
                {showMoney( value )}
            </div>
        </div>
    </Cond>;
}


export function FacilityParkerDetailsPage() {
    const { facilityId, parkerId } = useParamIds();
    const [showContactInfo, setShowContactInfo] = useState( false );
    const parker = useLoaderData() as ParkerDetails;

    const reservations  = parker.reservations;
    const subs          = parker.subscriptions;
    const resTotal      = reservations.map( r => r.price ).reduce( (a, b) => add( a, b ), cents( 0 ) );
    const monthlySpend  = subs.map( s => s.currentEntry )
                              .map( s => multiply( s.price, s.quantity ) )
                              .reduce( (a, b) => add( a, b ), cents( 0 ) );

    return (
        <Container fluid>
            <ContactModal parker={parker}
                          show={showContactInfo}
                          setShow={setShowContactInfo}
                          onSave={ () => 0 } />
            <Row>
                <Col>
                    <PageTitle className="d-flex align-items-center gap-2 mb-0">
                        {parker.name}
                    </PageTitle>
                    <div className="text-secondary">
                        {parker.emailAddress}&nbsp;
                        <Hyperlink onClick={ () => setShowContactInfo( true )}>
                            Edit Parker
                        </Hyperlink>
                    </div>
                    <div className="w-100 column-gap-5 px-4 my-4 d-flex">
                        <BigStat good={toCents( parker.balance ) <= 0 } label="Balance" value={parker.balance   }></BigStat>
                        <BigStat label="Total Spend"      value={parker.totalPaid }></BigStat>
                        <BigStat label="Total Obligation" value={parker.totalDebt }></BigStat>
                        <BigStat label="Monthly"          value={monthlySpend     }></BigStat>
                        <BigStat label="Reservations"     value={resTotal         }></BigStat>
                    </div>
                </Col>
                <Col md="12" className="g-0 px-0">
                    <div className="grid-col-1 grid-col-span-1 fs-5 fw-bold">
                        Active Plans
                    </div>
                    {subs.length == 0 && <>This parker does not have any active parking plans</>}
                    {subs.length > 0 && <Table hover cellPadding={5} className="grid-col-2">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Access Hours</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subs.map( sub => {
                                const s = sub.currentEntry;
                                return <tr key={s.subscriptionId}>
                                    <td>
                                        <Hyperlink to={`/facility/${facilityId}/parker/${parkerId}/plan/${s.subscriptionId}`}>
                                            {s.plan.name}
                                        </Hyperlink>
                                    </td>
                                    <td>{s.plan.accessHours}</td>
                                    <td>{s.quantity}</td>
                                    <td className="text-end">{showMoney( s.price )}</td>
                                    <td className="text-end">{showMoney( multiply( s.price, s.quantity ) )}</td>
                                </tr>;
                            } )}
                        </tbody>
                    </Table>}
                    <div className="grid-col-1 grid-col-span-1 fs-5 fw-bold">
                        Reservations
                    </div>
                    {reservations.length == 0 && <>This parker has not made any reservations</>}
                    {reservations.length > 0 && <Table hover cellPadding={5} striped className="grid-col-2">
                        <thead>
                            <tr>
                                <th>Rate</th>
                                <th>Check-In</th>
                                <th>Check-Out</th>
                                <th>Vehicle</th>
                                <th className="text-end">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservations.map( r => {
                                return <tr key={r.reservationId}>
                                    <td>
                                        <Hyperlink>
                                            {r.rateProgram.name}
                                        </Hyperlink>
                                    </td>
                                    <td>{showDateTime( r.start )}</td>
                                    <td>{showDateTime( r.end )}</td>
                                    <td>
                                        Not Specified
                                        {/* {r.vehicle.stateCode} {r.vehicle.licensePlateNumber} */}
                                    </td>
                                    <td className="text-end">
                                        {showMoney( r.price )}
                                    </td>
                                </tr>;
                            } )}
                        </tbody>
                    </Table>}
                </Col>
            </Row>
        </Container>
    );
}