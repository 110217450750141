import { Col, Container, Row } from "react-bootstrap";
import { ArrowUpCircle, CardHeading, CarFrontFill, CurrencyDollar, Person, PersonCircle, PersonFill, Receipt, ReceiptCutoff } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { useParamIds } from "./Data/Common";
import { MonitorParker } from "./Data/ApiTypes";

interface MenuItemProps {
    icon:     React.ReactNode;
    to:       string;
    text:     string;
    default?: boolean;
    active?:  boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const { active = false } = { ...props };
    return <NavLink to={props.to}
                    className={`facility-menu-item ${active ? "my-active" : "" }`}
                    end={props.default}>
        <div className="grid-col-2 align-items-center">
            {props.icon}
        </div>
        <div className="grid-col-4 align-items-center">
            {props.text}
        </div>
    </NavLink>;
}

export function AppFacilityParker() {
    const loc = useLocation();
    const { facilityId, parkerId } = useParamIds();
    const parker = useLoaderData() as MonitorParker;

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/facility/${facilityId}/parker/${parkerId}` ) ? ["", "d-none"] : ["d-none", ""];
    const active = loc.pathname === ( `/facility/${facilityId}/parker/${parkerId}` ) ? true : false;

    return <Container fluid className="fullheight pt-2">
        <Row className="fullheight">
            <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight`}>
                <div className="facility-menu-item fw-bold">
                    <PageTitle className="d-flex align-items-center justify-content-center gap-1 grid-col-span-5 text-center align-self-center fw-bold">
                        <PersonFill /> {parker.name}
                    </PageTitle>
                </div>
                <MenuItem default icon={<ArrowUpCircle />} to={`/facility/${facilityId}/parker`} text="Back to Parkers" />
                <MenuItem active={active}
                          default icon={<PersonCircle   />} to="./overview" text="Overview" />
                <MenuItem         icon={<ReceiptCutoff  />} to="./order"    text="Orders" />
                <MenuItem         icon={<CurrencyDollar />} to="./payment"  text="Payments" />
                <MenuItem         icon={<CarFrontFill   />} to="./vehicle"  text="Vehicles" />
            {/* <MenuItem         icon={<CardHeading    />} to="./billing"  text="Payment Methods" /> */}
            </Col>
            <Col md={7} lg={7} xl={8} xxl={9} className={`${contentClassName} d-md-block fullheight scroll-y-auto`}>
                <div className="d-md-none w-100 p-3 d-flex align-items-center justify-content-center">
                    <NavLink to="">
                        <ArrowUpCircle className="me-1" />Return to User Menu
                    </NavLink>
                </div>
                <Outlet />
            </Col>
        </Row>
    </Container>;
}