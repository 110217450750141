import { useState } from "react";
import { AddVehicleModal } from "./Controls/AddVehicleModal";
import { Button } from "./Controls/Button";
import { VehicleCard } from "./Controls/VehicleCard";
import { push } from "./Data/Util";
import { PageTitle } from "./Controls/PageTitle";
import { Vehicle } from "./Data/ApiTypes";
import { useLoaderData } from "react-router-dom";

export function FacilityParkerVehicleDetailsPage() {
    const loadedVehicles = useLoaderData() as Vehicle[];
    const [showAddVehicle, setShowAddVehicle] = useState( false );
    const [vehicles, setVehicles] = useState( [...loadedVehicles] );

    function addVehicle( veh: Vehicle ) {
        setVehicles( push( loadedVehicles, veh ) );
    }

    return <div>
        <AddVehicleModal show={showAddVehicle} setShow={setShowAddVehicle} onSubmit={addVehicle} />
        <PageTitle>My Vehicles</PageTitle>
        {loadedVehicles.map( ( veh, index ) =>
            <VehicleCard index={index} veh={veh} setVehicles={setVehicles} vehicles={loadedVehicles} />
        )}
        <div className="text-end">
            <Button className="w-100" onClick={() => setShowAddVehicle( true )}>Add Vehicle</Button>
        </div>
    </div>;
}