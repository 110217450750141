//third-party
import { useState     } from "react";
import { useForm      } from "react-hook-form";
import { Link         } from "react-router-dom";
import { At           } from "react-bootstrap-icons";
import { Alert, Card, Form } from "react-bootstrap";

//spotsync
import { Api          } from "./Data/Api";
import { Input        } from "./Controls/Input";
import { Button       } from "./Controls/Button";
import { CenteredPage } from "./Controls/CenteredPage";
import { HeaderText   } from "./Controls/HeaderText";

//maybe just use the api interface?
interface ForgotPasswordForm { emailAddress: string; }

export const ForgotPasswordPage = () => {
    const [msg, setMsg] = useState<string>();    

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordForm>();

    function submit( data: ForgotPasswordForm ) {
        Api.forgotPassword( data ).then( res => {
            if( res.isOk ) {
                setMsg( "If the email address is in our system, a password reset link will be sent to it." );
                return;
            }
            setMsg( "Unexpected error occurred." );
        } );
    }

    return <CenteredPage style={{ maxWidth: "30em" }}>
        <Card className="w-100">
            <Form onSubmit={handleSubmit( submit )}>
                <Card.Header>
                    <HeaderText>Forgot My Password</HeaderText>
                </Card.Header>
                <Card.Body className="">
                    <div className="text-secondary mb-3">
                        Enter your email and we'll send you a password reset link.
                    </div>
                    <Input type="email"
                        label="Email Address"
                        icon={<At className="fs-5" />}
                        error={errors.emailAddress}
                        {...register( "emailAddress", { required: "Email Address is required" } )}
                    />
                    {msg && <Alert variant="secondary">
                        {msg}
                    </Alert>}
                    <div>
                        <Link to="/login">Back to Log In</Link>
                    </div>
                </Card.Body>
                <Card.Footer >
                    <div className="d-flex flex-row justify-content-end gap-2">
                        { /* <Button className="w-100" onClick={() => nav( "/login" )}>
                                Back <span className="d-none d-sm-inline">to Log In</span>
                            </Button> */ }
                        <Button type="submit" className="w-50">
                            Send Link
                        </Button>
                    </div>
                </Card.Footer>
            </Form>
        </Card>
    </CenteredPage>;
};
