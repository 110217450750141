import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { ArrowUpCircle } from "react-bootstrap-icons";
import { PageTitle } from "./Controls/PageTitle";

export interface UserPage {
    text: string;
    url:  string;
}

export interface MenuItemProps extends UserPage {
    end?:    boolean;
    active?: boolean;
}

export function MenuItem( { text, url, active = false, end = false }: MenuItemProps ) {
    const loc = useLocation();
    const className = `${active ? "my-active" : "" }`;
    return <ListGroup.Item key={url}
                           as={NavLink}
                           to={url}     //props on NavLink
                           end={end}    //props on NavLink
                           className={className}>
        {text}
    </ListGroup.Item>;
}

export function AppUser() {
    const loc  = useLocation();

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === "/user" ? ["", "d-none"] : ["d-none", ""];
    const activeOverride = loc.pathname === ( `/user` ) ? true : false;

    return <Container className="pt-2">
        <Row>
            <Col md={4} className={`${menuClassName} d-md-block`}>
                <PageTitle>
                    Account
                </PageTitle>
                <ListGroup>
                    <MenuItem text="Overview" url={"/user/profile" } end active={activeOverride} />
                    <MenuItem text="Advanced" url={"/user/advanced" } />
                </ListGroup>
            </Col>
            <Col md={8} className={`${contentClassName} p-0 m-0 d-md-block`}>
                <div className="d-md-none w-100 p-3 d-flex flex-row align-items-center justify-content-center">
                    <NavLink to="">
                        <ArrowUpCircle className="me-1" />Return to My Account
                    </NavLink>
                </div>
                <div className="mx-2">
                    <Outlet />
                </div>
            </Col>
        </Row>
    </Container>;
}