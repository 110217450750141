import { Card } from "react-bootstrap";
import { del } from "../Data/Util";
import { Button } from "./Button";
import { getStateEnumFromCode, showStateName } from "../Data/UsState";
import { Vehicle } from "../Data/ApiTypes";

export interface VehicleCardProps {
    index:       number;
    veh:         Vehicle;
    setVehicles: ( veh: Vehicle[] ) => void;
    vehicles:    Vehicle[];
    disabled?:   boolean;
}

export function VehicleCard( { index, veh, setVehicles, vehicles, disabled = false }: VehicleCardProps ) {
    return <Card key={index} className="mb-2">
        <Card.Body className="gap-1"
            style={{
                display: "grid",
                alignItems: "center", /* color make model */
                gridTemplateColumns: "min-content 1fr auto"
            }}>
            <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: veh.color }} />
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {veh.make} {veh.model}
                </div>
                <div>
                    {showStateName( getStateEnumFromCode( veh.stateCode )! )} {veh.licensePlateNumber}
                </div>
            </div>
            {!disabled && <div className="w-100">
                <Button onClick={() => setVehicles( del( vehicles, veh ) )}>Remove</Button>
            </div>}
        </Card.Body>
    </Card>;
}
