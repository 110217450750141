import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";

interface CapacityModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: (data: CapacityForm) => void;
    postedCapacity: number;
    enforcedCapacity: number;
}

export interface CapacityForm {
    postedCapacity:   number;
    enforcedCapacity: number;
}

export function CapacityModal({ show, setShow, onSave, postedCapacity, enforcedCapacity }: CapacityModalProps) {
    console.log(postedCapacity);
    const { register, formState: { errors }, handleSubmit } = useForm<CapacityForm>({
        defaultValues: { enforcedCapacity: enforcedCapacity, postedCapacity: postedCapacity }
    });

    function submit(data: CapacityForm) {
        onSave(  data  );
        setShow( false );
    }

    return (
        <Modal show={show} centered onEscapeKeyDown={ () => setShow( false )}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header className="fs-5">
                    Edit Capacity
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="number"
                        {...register( "postedCapacity", {
                            required: "Posted capacity is required",
                            min: { value: 1, message: "Capacity must be at least 1" }
                        })}
                        label="Posted Capacity"
                        explanation="The number of physical parking spaces in the facility"
                        error={errors.postedCapacity}
                    />
                    <Input
                        type="number"
                        {...register("enforcedCapacity", {
                            required: "Enforced capacity is required",
                            min: { value: 1, message: "Capacity must be at least 1" }
                        })}
                        label="Enforced Capacity"
                        explanation="The number of virtual spaces.  Used to enforce oversell"
                        error={errors.enforcedCapacity}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}