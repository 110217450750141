
import { Link, useNavigate } from "react-router-dom";
import { Duration } from "js-joda";
import { showDurationShort } from "../Data/Date";
import { CardImage, PersonWalking } from "react-bootstrap-icons";
import { Button } from "./Button";

export interface ResultHourlyEntryProps {
    name:          string;
    thumbnailPrev: string | undefined;
    walkTime:      string;
    price:         string;
    duration:      string;
    className?:    string;
}

function defStr( str: string, def: string ) {
    if( str === "" ) {
        return def;
    }
    return str;
}

export function ResultHourlyEntryPreview( props: ResultHourlyEntryProps ) {
    const nav = useNavigate();
    let { name,
          thumbnailPrev,
          price,
          className = "", walkTime } = { ...props };
    
    name = defStr( name, "Facility" );
    
    return <div className={`facility-result rounded-4 p-4 mb-3 shadow border ${className}`}>
        <div>
            {thumbnailPrev ? <img className="facility-result-pic"
                 src={thumbnailPrev}
                 style={{ aspectRatio: "1 / 1", objectFit: "cover", width: "100%" }}
            /> : <CardImage className="facility-result-pic" width={"100px"} height={"100%"} />}
        </div>
        <div className="facility-result-info">
            <div className="grid-col-1">
                <div>
                    {name}
                </div>
                <div className="d-flex align-items-center">
                    <PersonWalking />&thinsp;{walkTime} min
                </div>
            </div>
            <div className="grid-col-2 position-relative">
                <div className="fs-5 fw-bold">
                    {price}
                </div>
                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }}>
                    for {showDurationShort( Duration.ofHours( 12 ) )}
                </div>
            </div>
            <div className="facility-result-bottom gap-1">
                <div style={{ justifySelf: "center", alignSelf: "center" }} className="">
                    <Link to="" className="w-100" onClick={ () => 0 }>
                        View Rates
                    </Link>
                </div>
                <div className="w-100">
                    <Button className="w-100" onClick={ () => 0 }>Book Now</Button>
                </div>
            </div>
        </div>
    </div>;
}