import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cents, showMoney } from "./Data/Money";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useLoaderData, useNavigate } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ParkerAgingRecord } from "./Data/ApiTypes";

export function FacilityReportAgingPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const agingRecords = useLoaderData() as ParkerAgingRecord[];

    const defaultColDef = useMemo<ColDef>(() => ({
        sortable: true,
        filter: true,
    }), []);

    const highlight = ( num: number, cls: string ) => num > 0 ? `text-end ${cls}` : 'text-end';

    const agingColumns = useMemo<ColDef<ParkerAgingRecord>[]>(() => [
        {
            field: "parker.parkerId",
            headerName: "ID",
            width: 80
        },
        {
            field: "parker.name",
            headerName: "Name",
            valueGetter: (params) => params.data!.parker.name
        },
        {
            field: "parker.emailAddress",
            headerName: "Email",
            valueGetter: (params) => params.data!.parker.emailAddress,
            flex: 1
        },
        {
            field: "days30",
            headerName: "30 Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-success" )
        },
        {
            field: "days60",
            headerName: "60 Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-warning" )
        },
        {
            field: "days90",
            headerName: "90+ Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value ) ),
            cellClass: (params) => highlight( params.value, "text-danger" )
        },
        {
            headerName: "Total",
            type: "rightAligned",
            valueGetter: (params) => params.data!.days30 + params.data!.days60 + params.data!.days90,
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-success fw-bold" )
        }
    ], []);

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = (event: any) => {
        nav(`/facility/${facilityId}/parker/${event.data.parker.parkerId}`);
    };

    // Add a sum for the footer
    const agingFooter = useMemo(() => {
        const total30 = agingRecords.reduce((sum, r) => sum + r.days30, 0);
        const total60 = agingRecords.reduce((sum, r) => sum + r.days60, 0);
        const total90 = agingRecords.reduce((sum, r) => sum + r.days90, 0);
        const grandTotal = total30 + total60 + total90;

        return [
            {
                parker: { parkerId: 0, firstName: 'Total', lastName: '', email: '' },
                days30: total30,
                days60: total60,
                days90: total90,
                total: grandTotal
            }
        ];
    }, [agingRecords]);

    return (
        <Container fluid className="pt-2 h-100">
            <Row className="h-100">
                <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto 1fr 5px", gridAutoColumns: "1fr" }}>
                    <div className="d-flex align-items-center">
                        <PageTitle className="ms-2 mb-2 me-3">
                            Parker Aging Report
                        </PageTitle>
                    </div>
                    <div className="ag-theme-alpine" style={{height: "100%", width: '100%'}}>
                        <AgGridReact
                            animateRows={false}
                            rowData={agingRecords}
                            defaultColDef={defaultColDef}
                            columnDefs={agingColumns}
                            onGridReady={onGridReady}
                            onRowClicked={onRowClicked}
                            pinnedBottomRowData={agingFooter}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}