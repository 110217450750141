import { forwardRef, ReactNode } from "react";
import { Form, FormSelectProps, InputGroup } from "react-bootstrap";
import { FieldError } from "react-hook-form";

export interface SelectProps extends FormSelectProps {
    label:            string;
    children:         ReactNode;
    icon?:            ReactNode;
    endIcon?:         ReactNode;
    className?:       string;
    selectClassName?: string;
    placeholder?:     string;
    explanation?:     string;
    error?:           FieldError | undefined;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    ( { className       = "mb-3",
        selectClassName = "",
        label,
        icon,
        endIcon,
        error,
        placeholder = "",
        explanation = "",
        children,
        ...props
    }, ref ) => {
        const x = <select />;
        return <Form.Group className={className}>
            <Form.Label className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>
                    {icon}
                </InputGroup.Text>}
                <Form.Select
                    className={selectClassName}
                    {...props}
                    ref={ref}>
                        {children}
                </Form.Select>
                {endIcon && <InputGroup.Text>
                    {endIcon}
                </InputGroup.Text>}
            </InputGroup>
            {explanation &&
                <Form.Text className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);