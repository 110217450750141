import { Col, Container, Row } from "react-bootstrap";
import { ReactNode } from "react";
import { ListNested, Tag } from "react-bootstrap-icons";
import { Button, ButtonProps } from "./Controls/Button";
import { useLoaderData, useMatch, useNavigate, useParams } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { PageTitle } from "./Controls/PageTitle";
import { Facility } from "./Data/ApiTypes";

interface RateTypeButtonProps extends ButtonProps {
    icon:  ReactNode;
    title: string;
    desc:  string;
    url:   string;
}

function RateTypeButton( { icon, title, desc, url, ...rest }: RateTypeButtonProps ) {
    const nav = useNavigate();
    const className = useMatch( url ) ? "border-primary shadow" : "border-primary";
    return <Button {...rest} className={`text-start ${className}`} style={{ height: "6em" }} onClick={ () => nav( url ) }>
        <div className="fs-5 fw-bold d-flex align-items-center">
            {icon}&nbsp;{title}
        </div>
        <div className="text-secondary fs-6" style={{ fontSize: "0.75em" }}>
            {desc}
        </div>
    </Button>;
}

export function FacilityRatesCreatePage() {

    const { facilityId } = useParamIds();
    const facility = useLoaderData() as Facility;

    const rateTypes = [
        { icon: <Tag />, title: "Fixed Rate",
          desc: "Each unit of time has the same cost",
          url:  `/facility/${facilityId}/rate/create/fixed` },
        { icon: <ListNested />, title: "Duration Based Rate",
          desc: "Give discounts or raise prices based on duration",
          url:  `/facility/${facilityId}/rate/create/duration` }
    ];

    return <Container fluid>
        <Row>
            <Col lg="4" className="mb-2">
                <PageTitle>Create New Rate</PageTitle>
                <div className="d-flex flex-column gap-2">
                    {rateTypes.map( ( r, i ) => <RateTypeButton
                        key={i}
                        url={r.url}
                        icon={r.icon}
                        title={r.title}
                        desc={r.desc} /> )}
                </div>
            </Col>
        </Row>
    </Container>;
}