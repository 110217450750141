export function BootstrapBreakpointDebugger() {
    return <div className="d-none d-sm-block" style={{
        color:        "white",
        fontFamily:   "monospace",
        fontSize:     "0.75em",
        background:   'rgba(247, 201, 241, 0.25)',
        padding:      '.5rem     1rem',
        borderRadius: '10px',
    }}>
        <div className="d-block d-sm-none">            xs  </div>
        <div className="d-none d-sm-block d-md-none">  sm  </div>
        <div className="d-none d-md-block d-lg-none">  md  </div>
        <div className="d-none d-lg-block d-xl-none">  lg  </div>
        <div className="d-none d-xl-block d-xxl-none" >xl  </div>
        <div className="d-none d-xxl-block" >          xxl </div>
    </div>
}