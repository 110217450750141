import { Col, Container, Row } from "react-bootstrap";
import { BarChartSteps, Building, BuildingFill, Calculator, Calendar, FileText, People, TextLeft } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { SearchResult } from "./Data/ApiTypes";
import { PageTitle } from "./Controls/PageTitle";
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parseSearchResult } from "./Data/ApiParse";
import { FacilityListSearch } from "./Controls/FacilityListSearch";
import { EntityType } from "./Data/ApiTransport";
import { ExtendedFacility } from ".";
import { ConnectComponentsProvider } from "@stripe/react-connect-js";

interface MenuItemProps {
    icon:     React.ReactNode;
    to:       string;
    text:     string;
    default?: boolean;
    active?:  boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const { active = false } = { ...props };
    return <NavLink to={props.to}
                    className={`facility-menu-item ${active ? "my-active" : "" }`}
                    end={props.default}>
        <div className="grid-col-2 align-self-center">
            {props.icon}
        </div>
        <div className="grid-col-4 align-self-center">
            {props.text}
        </div>
    </NavLink>;
}

export function AppFacility() {
    const nav      = useNavigate();
    const loc      = useLocation();
    const facility = useLoaderData() as ExtendedFacility;

    const facilityId = facility.facilityId;
    const [search, setSearch] = useState<string>();
    const [results, setResults] = useState<SearchResult[]>();

    const doSearch = async ( search: string ) => {
        const res = await Api.facilitySearch( facilityId, search );
        processResult( res, val => setResults( val.map( parseSearchResult ) ), err => undefined );
    }

    useEffect( () => {
        if( !search ) {
            return;
        }
        doSearch( search );
    }, [search] );

    function onSelect( res: SearchResult ) {
        switch( res.type ) {
            case EntityType.Parker:
                nav( `/facility/${facilityId}/parker/${res.id}` )
                return;

            case EntityType.Staff:
                alert( "Nav to staff not implemented" );
                return;

            case EntityType.Vehicle:
                alert( "Nav to vehicle not implemented" );
                return;

            default:
                throw new Error( "Entity Type Invalid" );
        }
    }

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/facility/${facilityId}` ) ? ["", "d-none"] : ["d-none", ""];
    const activeOverride = loc.pathname === ( `/facility/${facilityId}` ) ? true : false;
    return <Container fluid className="fullheight pt-2">
        <ConnectComponentsProvider connectInstance={facility.connectInstance}>
            <Row className="fullheight">
                <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight scroll-y-auto`}>
                    <div className="facility-menu-item fw-bold">
                        <PageTitle className="grid-col-span-5 text-center align-items-center justify-content-center gap-1 d-flex align-self-center fw-bold">
                            <BuildingFill /> {facility.name}
                        </PageTitle>
                    </div>
                    <MenuItem active={activeOverride}
                        default icon={<Building />} to="./overview" text="Overview" />
                    <MenuItem icon={<Calculator />} to="./payments" text="Payments" />
                    <MenuItem icon={<FileText />} to="./payouts" text="Payouts" />
                    <MenuItem icon={<TextLeft />} to="./listing" text="Listing" />
                    <MenuItem icon={<People />} to="./parker" text="Parkers" />
                    <MenuItem icon={<FileText />} to="./invoice" text="Invoices" />
                    <MenuItem icon={<Calendar />} to="./plan" text="Plans" />
                    <MenuItem icon={<Calculator />} to="./rate" text="Rates" />
                    <MenuItem icon={<BarChartSteps />} to="./report/aging" text="Aging" />
                {/* <MenuItem         icon={<Clock         />}  to="./schedule" text="Schedule"   /> */}
                </Col>
                <Col md={7} lg={7} xl={8} xxl={9} className={`${contentClassName} p-0 m-0 d-md-grid h-100 scroll-y-auto`}
                    style={{ gridTemplateRows: "1fr" }}>
                    {/* <div className="d-md-none w-100 p-3 d-flex align-items-center justify-content-center">
                        <NavLink to="">
                            <ArrowUpCircle className="me-1" />Return to Facility Menu
                        </NavLink>
                    </div> */}
                    <Outlet />
                </Col>
            </Row>
        </ConnectComponentsProvider>
    </Container>;
}