import React from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { cents, Money, showMoney } from "./Data/Money";
import { CurrencyDollar, Hash } from "react-bootstrap-icons";
import { multiply } from "dinero.js";
import { MoneyInput } from "./Controls/MoneyInput";

interface AddRecipientModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: (recipientInfo: LineItem) => void;
}

export interface LineItem {
    desc:      string;
    cat:       string;
    unitPrice: number;
    qty:       number;
}

export function AddLineItemModal( { show, setShow, onSave }: AddRecipientModalProps) {
    const { register, watch, formState: { errors }, handleSubmit } = useForm<LineItem>( { defaultValues: {
        desc:      "",
        cat:       "",
        unitPrice: 0,
        qty:       1
    } } );
    const vals = watch();

    function submit( data: LineItem ) {
        console.log(data);
        onSave( data );
        setShow(false);
    }

    let total = "Invalid Unit Price or Quantity";
    let totalClassName = "text-danger";
    try {
        total = showMoney( multiply( cents( vals.unitPrice * 100.0 ), vals.qty ) );
        totalClassName = "text-success";
    }
    catch {}

    return (
        <Modal show={show} size="lg" centered onEscapeKeyDown={() => setShow(false)}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header className="fs-5">
                    Add Line Item
                </Modal.Header>
                <Modal.Body className="add-recipient">
                    <div className="two-col">
                        <Input className="mb-2"
                            type="text"
                            {...register( "desc", { required: "Product or Service is required" })}
                            label="Product or Service"
                            error={errors.desc}
                        />
                        <Input className="mb-2"
                            type="text"
                            {...register( "cat", { required: "Category is required" })}
                            label="Category"
                            error={errors.cat}
                        />
                    </div>
                    <div className="two-col">
                        <Input className="mb-2"
                            type="number"
                            icon={<CurrencyDollar />}
                            min={0}
                            minLength={1}
                            step={0.01}
                            {...register( "unitPrice", {
                                required: "Unit Price is required",
                                min: 0,
                                valueAsNumber: true
                            })}
                            label="Unit Price"
                            error={errors.unitPrice}
                        />
                        <Input className="mb-2"
                            type="number"
                            icon={<Hash />}
                            {...register( "qty", {
                                required:      "Quantity is required",
                                valueAsNumber: true
                            })}
                            label="Quantity"
                            error={errors.qty}
                        />
                    </div>
                    <div className="two-col mt-2">
                        <div className="text-end">Total Price</div>
                        <div className={`text-end fw-bold text-success ${totalClassName}`}>
                            {total}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}