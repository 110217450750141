import { Card } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { UserPageDeleteAccountModal } from "./UserPageDeleteAccountModal";
import { useState } from "react";
import { PageTitle } from "./Controls/PageTitle";

export function UserAdvancedPage() {
    const [showDel, setShowDel] = useState( false );
    return <div>
        <PageTitle>
            Advanced
        </PageTitle>
        <UserPageDeleteAccountModal
            show={showDel}
            setShow={setShowDel} />
        <Card className="mt-2">
            <Card.Header className="text-center">
                Advanced Account Actions
            </Card.Header>
            <Card.Body>
                <div className="grid-col-1 py-3 grid-col-span-3 text-center">
                    <Button className="border-danger text-danger w-50"
                        onClick={() => setShowDel( true )}>
                        Delete My Account
                    </Button>
                </div>
            </Card.Body>
        </Card>
    </div>;
}