import React from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";

interface AddRecipientModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: (recipientInfo: RecipientForm) => void;
}

export interface RecipientForm {
    firstName:         string;
    lastName:          string;
    address1:          string;
    address2:          string;
    city:              string;
    state:             string;
    zip:               string;
    company:           string;
    emailAddress:      string;
    referenceId:       string;
    mobilePhone:       string;
    sendInvoiceViaSMS: boolean;
}

export function AddRecipientModal( { show, setShow, onSave }: AddRecipientModalProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<RecipientForm>();

    function submit( data: RecipientForm ) {
        onSave( data );
        setShow(false);
    }

    return (
        <Modal show={show} size="lg" centered onEscapeKeyDown={() => setShow(false)}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header className="fs-5">
                    Add Recipient
                </Modal.Header>
                <Modal.Body className="add-recipient">
                    <div className="two-col">
                        <Input className="mb-2"
                            type="text"
                            {...register("firstName", { required: "First name is required" })}
                            label="First Name"
                            error={errors.firstName}
                        />
                        <Input className="mb-2"
                            type="text"
                            {...register("lastName", { required: "Last name is required" })}
                            label="Last Name"
                            error={errors.lastName}
                        />
                    </div>
                    <div className="two-col">
                        <Input className="mb-2"
                            type="email"
                            {...register("emailAddress", {
                                required: "Email address is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            label="Email Address (Required)"
                            error={errors.emailAddress}
                        />
                        <Input className="mb-2"
                            type="tel"
                            {...register("mobilePhone", {
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Invalid phone number (10 digits required)"
                                }
                            })}
                            label="Mobile Phone"
                            explanation="Optional"
                            error={errors.mobilePhone}
                        />
                    </div>
                    <div className="two-col">
                        <Input className="mb-2"
                            type="text"
                            {...register("address1")}
                            label="Address"
                        />
                        <div></div>
                    </div>
                    <div className="two-col">
                        <Input className="mb-2"
                            type="text"
                            {...register("address2")}
                            label="Second Address Line"
                            explanation="Optional"
                        />
                        <Input className="mb-2"
                                type="text"
                                {...register("city")}
                                label="City"
                            />
                    </div>
                    <div className="two-col">
                        <Input className="mb-2"
                            type="text"
                            {...register("state")}
                            label="State"
                        />
                        <Input className="mb-2"
                            type="text"
                            {...register("zip")}
                            label="Zip"
                        />
                    </div>
                    <Input className="mb-2"
                        type="text"
                        {...register("referenceId")}
                        label="Optional Reference Number"
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}