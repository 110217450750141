//third-party
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";

//spotsync
import { Api } from "./Data/Api";
import { Plan, PlanUpdateReq } from "./Data/ApiTypes";
import { Input } from "./Controls/Input";
import { Select } from "./Controls/Select";
import { MediumPage } from "./Controls/MediumPage";
import { PageTitle } from "./Controls/PageTitle";
import { useParamIds } from "./Data/Common";
import { Button } from "./Controls/Button";
import { showMoney } from "./Data/Money";
import { processResult } from "./Data/Result";
import { useState } from "react";
import { SmallPage } from "./Controls/SmallPage";
import { Calendar, CalendarEvent, CalendarX, Envelope, Printer } from "react-bootstrap-icons";

export function FacilityPlanEditPage() {
    const nav = useNavigate();
    const [loading, setLoading] = useState( false );
    const [msg, setMsg] = useState<string>();
    const { facilityId, planId } = useParamIds();
    const plan   = useLoaderData() as Plan;
    const prices = plan.prices;
    const { register, handleSubmit, formState: { errors } } = useForm<PlanUpdateReq>( {
        defaultValues: { ...plan }
    } );

    const onSubmit = async ( data: PlanUpdateReq ) => {
        setLoading( true );
        Api.facilityPlanUpdate( facilityId, planId, data ).then(
            res => processResult( res,
                val => {
                    setMsg( "Plan Updated Successfully" );
                    setLoading( false );
                    nav( `/facility/${facilityId}/plan` );
                },
                err => { setMsg( err ); }
            )
        );
    };

    return <MediumPage>
        <PageTitle>
            Edit Plan "{plan.name}"
        </PageTitle>
        <form onSubmit={handleSubmit( onSubmit )}>
            <Input
                type="text"
                label="Name"
                {...register( "name", { required: "Name is required" } )}
                error={errors.name}
            />

            <Input
                type="text"
                label="Description"
                as="textarea"
                rows={3}
                {...register( "description", { required: "Description is required" } )}
                error={errors.description}
            />

            <Input
                type="text"
                label="Access Hours"
                placeholder="Mon - Fri 7am - 7pm"
                {...register( "accessHours", { required: "Access hours are required" } )}
                error={errors.accessHours}
            />

            <Container fluid className="g-0">
                <Row>
                    <Col className="fw-bold h4">
                        Scheduling
                        <p className="text-secondary fs-6 fw-normal">
                            Choose the day of the month that invoices are generated, payment is due, and late fees are accessed
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Send Invoices"
                            icon={<Calendar />}
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthInvoice" )}
                            error={errors.dayOfMonthInvoice}
                        />
                    </Col>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Payment Due"
                            icon={<Calendar />}
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthDue" )}
                            error={errors.dayOfMonthDue}
                        />
                    </Col>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Late Fee Day"
                            icon={<CalendarX />}
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthLate" )}
                            error={errors.dayOfMonthLate}
                        />
                    </Col>
                </Row>
            </Container>

            <Select
                label="List Price"
                explanation="This is the price displayed in search results in SpotSync Connect"
                {...register( "planPriceId", { setValueAs: v => v === "" ? null : parseInt( v ) } )}>
                <option value="">Unlisted</option>
                {prices?.map( price => (
                    <option key={price.planPriceId}
                            value={price.planPriceId}>
                        {showMoney( price.price )}
                    </option>
                ) )}
            </Select>

            <Input
                type="text"
                label="Change Note"
                explanation="The reason for the change"
                as="textarea"
                rows={2}
                {...register( "note" )}
                error={errors.note}
            />

            <div className="d-flex justify-content-end gap-2">
                <Button type="submit" loading={loading}>
                    Save Changes
                </Button>
            </div>
        </form>
    </MediumPage>;
}