import { Alert, Col, Container, Row } from "react-bootstrap";

//spotsync controls

//spotsync data
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { HeaderText } from "./Controls/HeaderText";
import { ConnectNotificationBanner, ConnectBalances, ConnectAccountManagement } from "@stripe/react-connect-js";
import { ExtendedFacility } from ".";
import { FacilityListSearch } from "./Controls/FacilityListSearch";
import { useEffect, useState } from "react";
import { SearchResult } from "./Data/ApiTypes";
import { useParamIds } from "./Data/Common";
import { parseSearchResult } from "./Data/ApiParse";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { EntityType } from "./Data/ApiTransport";

export function FacilityOverviewPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const facility = useRouteLoaderData( "facility" ) as ExtendedFacility;
    const acct     = facility.account;
    const [search, setSearch] = useState<string>();
    const [results, setResults] = useState<SearchResult[]>();

    const doSearch = async ( search: string ) => {
        const res = await Api.facilitySearch( facilityId, search );
        processResult( res, val => setResults( val.map( parseSearchResult ) ), err => undefined );
    }

    useEffect( () => {
        if( !search ) {
            return;
        }
        doSearch( search );
    }, [search] );

    function onSelect( res: SearchResult ) {
        switch( res.type ) {
            case EntityType.Parker:
                nav( `/facility/${facilityId}/parker/${res.id}` )
                return;

            case EntityType.Staff:
                alert( "Nav to staff not implemented" );
                return;

            case EntityType.Vehicle:
                alert( "Nav to vehicle not implemented" );
                return;

            default:
                throw new Error( "Entity Type Invalid" );
        }
    }

    return <Container fluid>
        <Row>
            <Col>
                <FacilityListSearch
                        value={search ?? ''}
                        results={results}
                        onChange={setSearch}
                        onSelect={onSelect}
                        placeholder="Enter a Name, License Plate, etc..."
                />
                {/* <PageTitle>
                    Overview
                </PageTitle> */}
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="12">
                <ConnectNotificationBanner collectionOptions={{ fields: "eventually_due", futureRequirements: "include" }} />
                <br />
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="12">
                <HeaderText>Balance</HeaderText>
                <ConnectBalances />
                <br />
            </Col>
        </Row>
        {acct.isOnboarded && <ConnectAccountManagement />}
        {!acct.isOnboarded && <Row>
            <Col>
                <Alert variant="warning">
                    You have not completed onboarding. <a target="_blank" href={acct.connectLink!}>
                        Click here to finish onboarding.
                    </a>
                </Alert>
            </Col>
        </Row>}
    </Container>;
}
