import { OrderType } from "./ApiTransport"; 
import { PaymentType } from "./ApiTransport"; 
import { OrderInitiator } from "./ApiTransport"; 
import { OrderBalanceType } from "./ApiTransport"; 
import { EntityType } from "./ApiTransport"; 
import { SubscriptionStatus } from "./ApiTransport"; import { OperatorApi } from "./ApiTransport"; 
import { FacilityInfoApi } from "./ApiTransport"; 
import { UsAddressApi } from "./ApiTransport"; 
import { CoordinatesApi } from "./ApiTransport"; 
import { VehicleApi } from "./ApiTransport"; 
import { RateProgramApi } from "./ApiTransport"; 
import { RateEntryApi } from "./ApiTransport"; 
import { FacilityImageApi } from "./ApiTransport"; 
import { ListedPlanApi } from "./ApiTransport"; 
import { OrderDetailsApi } from "./ApiTransport"; 
import { OrderApi } from "./ApiTransport"; 
import { OrderBalanceApi } from "./ApiTransport"; 
import { InvoiceApi } from "./ApiTransport"; 
import { PaymentApi } from "./ApiTransport"; 
import { ApplyPaymentApi } from "./ApiTransport"; 
import { OrderInfoApi } from "./ApiTransport"; 
import { PaymentChangeApi } from "./ApiTransport"; 
import { OrderDebtApi } from "./ApiTransport"; 
import { StripeLinkRespApi } from "./ApiTransport"; 
import { SearchResultApi } from "./ApiTransport"; 
import { PlanCreateReqApi } from "./ApiTransport"; 
import { PlanUpdateReqApi } from "./ApiTransport"; 
import { PlanPriceAddReqApi } from "./ApiTransport"; 
import { PlanPriceListRespApi } from "./ApiTransport"; 
import { ReservationApi } from "./ApiTransport"; 
import { SubscriptionApi } from "./ApiTransport"; 
import { SubscriptionEntryApi } from "./ApiTransport"; 
import { PlanInfoApi } from "./ApiTransport"; 
import { FacilityApi } from "./ApiTransport"; 
import { MonitorParkerApi } from "./ApiTransport"; 
import { ParkerListEntryApi } from "./ApiTransport"; 
import { ParkerDetailsApi } from "./ApiTransport"; 
import { CheckCreateReqApi } from "./ApiTransport"; 
import { PmtApplyApi } from "./ApiTransport"; 
import { PlanApi } from "./ApiTransport"; 
import { PlanDetailsApi } from "./ApiTransport"; 
import { PlanChangeApi } from "./ApiTransport"; 
import { PlanPriceInfoApi } from "./ApiTransport"; 
import { PlanPriceDetailsApi } from "./ApiTransport"; 
import { InviteParkerReqApi } from "./ApiTransport"; 
import { FacilityCreateReqApi } from "./ApiTransport"; 
import { FacilityUpdateReqApi } from "./ApiTransport"; 
import { ParkerAgingRecordApi } from "./ApiTransport"; 
import { ParkerInfoApi } from "./ApiTransport"; 
import { RateProgramDataApi } from "./ApiTransport"; 
import { RateEntryDataApi } from "./ApiTransport"; 
import { SubscriberInfoApi } from "./ApiTransport"; 
import { SubscriptionUpdateReqApi } from "./ApiTransport"; 
import { SubEntryApi } from "./ApiTransport"; 
import { SubEntryForEditApi } from "./ApiTransport"; 
import { AccessPeriodInfoApi } from "./ApiTransport"; import { Operator } from "./ApiTypes"; 
import { FacilityInfo } from "./ApiTypes"; 
import { UsAddress } from "./ApiTypes"; 
import { Coordinates } from "./ApiTypes"; 
import { Vehicle } from "./ApiTypes"; 
import { RateProgram } from "./ApiTypes"; 
import { RateEntry } from "./ApiTypes"; 
import { FacilityImage } from "./ApiTypes"; 
import { ListedPlan } from "./ApiTypes"; 
import { OrderDetails } from "./ApiTypes"; 
import { Order } from "./ApiTypes"; 
import { OrderBalance } from "./ApiTypes"; 
import { Invoice } from "./ApiTypes"; 
import { Payment } from "./ApiTypes"; 
import { ApplyPayment } from "./ApiTypes"; 
import { OrderInfo } from "./ApiTypes"; 
import { PaymentChange } from "./ApiTypes"; 
import { OrderDebt } from "./ApiTypes"; 
import { StripeLinkResp } from "./ApiTypes"; 
import { SearchResult } from "./ApiTypes"; 
import { PlanCreateReq } from "./ApiTypes"; 
import { PlanUpdateReq } from "./ApiTypes"; 
import { PlanPriceAddReq } from "./ApiTypes"; 
import { PlanPriceListResp } from "./ApiTypes"; 
import { Reservation } from "./ApiTypes"; 
import { Subscription } from "./ApiTypes"; 
import { SubscriptionEntry } from "./ApiTypes"; 
import { PlanInfo } from "./ApiTypes"; 
import { Facility } from "./ApiTypes"; 
import { MonitorParker } from "./ApiTypes"; 
import { ParkerListEntry } from "./ApiTypes"; 
import { ParkerDetails } from "./ApiTypes"; 
import { CheckCreateReq } from "./ApiTypes"; 
import { PmtApply } from "./ApiTypes"; 
import { Plan } from "./ApiTypes"; 
import { PlanDetails } from "./ApiTypes"; 
import { PlanChange } from "./ApiTypes"; 
import { PlanPriceInfo } from "./ApiTypes"; 
import { PlanPriceDetails } from "./ApiTypes"; 
import { InviteParkerReq } from "./ApiTypes"; 
import { FacilityCreateReq } from "./ApiTypes"; 
import { FacilityUpdateReq } from "./ApiTypes"; 
import { ParkerAgingRecord } from "./ApiTypes"; 
import { ParkerInfo } from "./ApiTypes"; 
import { RateProgramData } from "./ApiTypes"; 
import { RateEntryData } from "./ApiTypes"; 
import { SubscriberInfo } from "./ApiTypes"; 
import { SubscriptionUpdateReq } from "./ApiTypes"; 
import { SubEntry } from "./ApiTypes"; 
import { SubEntryForEdit } from "./ApiTypes"; 
import { AccessPeriodInfo } from "./ApiTypes"; 
import { toCents } from "./Money";
import { emitDuration, emitLocalDateTime, emitLocalTime, emitLocalDate, emitInstant } from "./ApiConverters";
import { Duration } from "js-joda";
export function emitOperator(o: Operator): OperatorApi {
    return {
        operatorId: o.operatorId,
        name: o.name,
        address: emitUsAddress( o.address ),
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber,
        website: o.website,
        topTitle: o.topTitle,
        topText: o.topText,
        bottomLeftTitle: o.bottomLeftTitle,
        bottomLeftText: o.bottomLeftText,
        bottomRightTitle: o.bottomRightTitle,
        bottomRightText: o.bottomRightText,
    }
}

export function emitFacilityInfo(f: FacilityInfo): FacilityInfoApi {
    return {
        facilityId: f.facilityId,
        operatorId: f.operatorId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
    }
}

export function emitUsAddress(u: UsAddress): UsAddressApi {
    return {
        addressLine1: u.addressLine1,
        addressLine2: u.addressLine2,
        city: u.city,
        state: u.state,
        zipCode: u.zipCode,
    }
}

export function emitCoordinates(c: Coordinates): CoordinatesApi {
    return {
        lat: c.lat,
        lng: c.lng,
    }
}

export function emitVehicle(v: Vehicle): VehicleApi {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
    }
}

export function emitRateProgram(r: RateProgram): RateProgramApi {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => emitRateEntry( el ) ),
    }
}

export function emitRateEntry(r: RateEntry): RateEntryApi {
    return {
        rateEntryId: r.rateEntryId,
        rateProgramId: r.rateProgramId,
        upTo: emitDuration( r.upTo ),
        rate: toCents( r.rate ),
    }
}

export function emitFacilityImage(f: FacilityImage): FacilityImageApi {
    return {
        facilityImageId: f.facilityImageId,
        storedImageId: f.storedImageId,
        orderId: f.orderId,
        caption: f.caption,
        altText: f.altText,
    }
}

export function emitListedPlan(l: ListedPlan): ListedPlanApi {
    return {
        planId: l.planId,
        planEntryId: l.planEntryId,
        name: l.name,
        description: l.description,
        accessHours: l.accessHours,
        dayOfMonthInvoice: l.dayOfMonthInvoice,
        dayOfMonthDue: l.dayOfMonthDue,
        dayOfMonthLate: l.dayOfMonthLate,
        price: toCents( l.price ),
    }
}

export function emitOrderDetails(o: OrderDetails): OrderDetailsApi {
    return {
        order: emitOrder( o.order ),
        balanceChanges: o.balanceChanges.map( el => emitOrderBalance( el ) ),
        summary: o.summary,
    }
}

export function emitOrder(o: Order): OrderApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        type: o.type,
        facility: emitFacilityInfo( o.facility ),
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitOrderBalance(o: OrderBalance): OrderBalanceApi {
    return {
        orderBalanceId: o.orderBalanceId,
        type: o.type,
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        debt: toCents( o.debt ),
        paid: toCents( o.paid ),
        balance: toCents( o.balance ),
        totalDebt: toCents( o.totalDebt ),
        totalPaid: toCents( o.totalPaid ),
        reason: o.reason != null ? o.reason : null,
        paymentId: o.paymentId != null ? o.paymentId : null,
    }
}

export function emitInvoice(i: Invoice): InvoiceApi {
    return {
        invoiceId: i.invoiceId,
        orderId: i.orderId,
        createdWhen: emitInstant( i.createdWhen ),
        dueWhen: emitLocalDate( i.dueWhen ),
        openingBalance: toCents( i.openingBalance ),
        endingBalance: toCents( i.endingBalance ),
        order: emitOrderInfo( i.order ),
        facility: emitFacilityInfo( i.facility ),
    }
}

export function emitPayment(p: Payment): PaymentApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        facility: emitFacilityInfo( p.facility ),
        parkerId: p.parkerId,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        remaining: toCents( p.remaining ),
        paymentMethod: p.paymentMethod,
        changes: p.changes.map( el => emitPaymentChange( el ) ),
        applications: p.applications.map( el => emitApplyPayment( el ) ),
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitApplyPayment(a: ApplyPayment): ApplyPaymentApi {
    return {
        orderBalanceId: a.orderBalanceId,
        type: a.type,
        facilityId: a.facilityId,
        parkerId: a.parkerId,
        orderId: a.orderId,
        debt: toCents( a.debt ),
        paid: toCents( a.paid ),
        balance: toCents( a.balance ),
        totalDebt: toCents( a.totalDebt ),
        totalPaid: toCents( a.totalPaid ),
    }
}

export function emitOrderInfo(o: OrderInfo): OrderInfoApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        orderType: o.orderType,
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitPaymentChange(p: PaymentChange): PaymentChangeApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        parkerId: p.parkerId,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitOrderDebt(o: OrderDebt): OrderDebtApi {
    return {
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        orderTotal: toCents( o.orderTotal ),
        paid: toCents( o.paid ),
        unpaid: toCents( o.unpaid ),
    }
}

export function emitStripeLinkResp(s: StripeLinkResp): StripeLinkRespApi {
    return {
        isOnboarded: s.isOnboarded,
        connectLink: s.connectLink != null ? s.connectLink : null,
    }
}

export function emitSearchResult(s: SearchResult): SearchResultApi {
    return {
        searchResultId: s.searchResultId,
        type: s.type,
        id: s.id,
        name: s.name,
    }
}

export function emitPlanCreateReq(p: PlanCreateReq): PlanCreateReqApi {
    return {
        facilityId: p.facilityId,
        name: p.name,
        accessHours: p.accessHours,
        description: p.description,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        initialPrice: p.initialPrice,
        terms: p.terms,
    }
}

export function emitPlanUpdateReq(p: PlanUpdateReq): PlanUpdateReqApi {
    return {
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        note: p.note,
        terms: p.terms,
    }
}

export function emitPlanPriceAddReq(p: PlanPriceAddReq): PlanPriceAddReqApi {
    return {
        name: p.name,
        price: p.price,
    }
}

export function emitPlanPriceListResp(p: PlanPriceListResp): PlanPriceListRespApi {
    return {
        planPriceId: p.planPriceId,
        name: p.name,
        price: p.price,
    }
}

export function emitReservation(r: Reservation): ReservationApi {
    return {
        reservationId: r.reservationId,
        facilityId: r.facilityId,
        parkerId: r.parkerId,
        start: emitLocalDateTime( r.start ),
        end: emitLocalDateTime( r.end ),
        actualStart: emitInstant( r.actualStart ),
        actualEnd: emitInstant( r.actualEnd ),
        duration: emitDuration( r.duration ),
        rateProgramId: r.rateProgramId,
        price: toCents( r.price ),
        createdWhen: emitInstant( r.createdWhen ),
        facility: emitFacilityInfo( r.facility ),
        rateProgram: emitRateProgram( r.rateProgram ),
    }
}

export function emitSubscription(s: Subscription): SubscriptionApi {
    return {
        subscriptionId: s.subscriptionId,
        start: emitLocalDate( s.start ),
        currentEntry: emitSubscriptionEntry( s.currentEntry ),
        history: s.history.map( el => emitSubscriptionEntry( el ) ),
        facility: emitFacilityInfo( s.facility ),
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
    }
}

export function emitSubscriptionEntry(s: SubscriptionEntry): SubscriptionEntryApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
        price: toCents( s.price ),
        plan: emitPlanInfo( s.plan ),
    }
}

export function emitPlanInfo(p: PlanInfo): PlanInfoApi {
    return {
        planId: p.planId,
        facilityId: p.facilityId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
    }
}

export function emitFacility(f: Facility): FacilityApi {
    return {
        facilityId: f.facilityId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        physicalCapacity: f.physicalCapacity,
        enforcedCapacity: f.enforcedCapacity,
        images: f.images.map( el => emitFacilityImage( el ) ),
        hourlyRates: f.hourlyRates.map( el => emitRateProgram( el ) ),
        listedPlans: f.listedPlans.map( el => emitListedPlan( el ) ),
    }
}

export function emitMonitorParker(m: MonitorParker): MonitorParkerApi {
    return {
        parkerId: m.parkerId,
        operatorId: m.operatorId,
        name: m.name,
        emailAddress: m.emailAddress,
        phoneNumber: m.phoneNumber,
        note: m.note,
    }
}

export function emitParkerListEntry(p: ParkerListEntry): ParkerListEntryApi {
    return {
        parkerId: p.parkerId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber,
        note: p.note,
        balance: toCents( p.balance ),
        totalDebt: toCents( p.totalDebt ),
        totalPaid: toCents( p.totalPaid ),
    }
}

export function emitParkerDetails(p: ParkerDetails): ParkerDetailsApi {
    return {
        operatorId: p.operatorId,
        parkerId: p.parkerId,
        name: p.name,
        phoneNumber: p.phoneNumber,
        note: p.note,
        emailAddress: p.emailAddress,
        balance: toCents( p.balance ),
        totalDebt: toCents( p.totalDebt ),
        totalPaid: toCents( p.totalPaid ),
        subscriptions: p.subscriptions.map( el => emitSubscription( el ) ),
        reservations: p.reservations.map( el => emitReservation( el ) ),
    }
}

export function emitCheckCreateReq(c: CheckCreateReq): CheckCreateReqApi {
    return {
        checkDate: emitLocalDate( c.checkDate ),
        checkNumber: c.checkNumber,
        checkMemo: c.checkMemo,
        amount: c.amount,
    }
}

export function emitPmtApply(p: PmtApply): PmtApplyApi {
    return {
        orderId: p.orderId,
        amount: toCents( p.amount ),
    }
}

export function emitPlan(p: Plan): PlanApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => emitPlanPriceDetails( el ) ),
        note: p.note,
    }
}

export function emitPlanDetails(p: PlanDetails): PlanDetailsApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        terms: p.terms,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => emitPlanPriceDetails( el ) ),
        history: p.history.map( el => emitPlanChange( el ) ),
    }
}

export function emitPlanChange(p: PlanChange): PlanChangeApi {
    return {
        planEntryId: p.planEntryId,
        planId: p.planId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        createdWhen: emitInstant( p.createdWhen ),
        createdByStaffId: p.createdByStaffId,
        note: p.note,
    }
}

export function emitPlanPriceInfo(p: PlanPriceInfo): PlanPriceInfoApi {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: toCents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitPlanPriceDetails(p: PlanPriceDetails): PlanPriceDetailsApi {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: toCents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: emitInstant( p.createdWhen ),
        subscribers: p.subscribers.map( el => emitSubscriberInfo( el ) ),
    }
}

export function emitInviteParkerReq(i: InviteParkerReq): InviteParkerReqApi {
    return {
        name: i.name,
        emailAddress: i.emailAddress,
        phoneNumber: i.phoneNumber != null ? i.phoneNumber : null,
        planId: i.planId,
        planPriceId: i.planPriceId,
        quantity: i.quantity,
        message: i.message,
    }
}

export function emitFacilityCreateReq(f: FacilityCreateReq): FacilityCreateReqApi {
    return {
        name: f.name,
        address: emitUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
        imageThumb: f.imageThumb,
        bannerImage: f.bannerImage,
    }
}

export function emitFacilityUpdateReq(f: FacilityUpdateReq): FacilityUpdateReqApi {
    return {
        facilityId: f.facilityId,
        name: f.name,
        address: emitUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
    }
}

export function emitParkerAgingRecord(p: ParkerAgingRecord): ParkerAgingRecordApi {
    return {
        parker: emitParkerInfo( p.parker ),
        orderId: p.orderId,
        days30: p.days30,
        days60: p.days60,
        days90: p.days90,
    }
}

export function emitParkerInfo(p: ParkerInfo): ParkerInfoApi {
    return {
        parkerId: p.parkerId,
        name: p.name,
        note: p.note,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
    }
}

export function emitRateProgramData(r: RateProgramData): RateProgramDataApi {
    return {
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => emitRateEntryData( el ) ),
    }
}

export function emitRateEntryData(r: RateEntryData): RateEntryDataApi {
    return {
        upTo: emitDuration( r.upTo ),
        rate: toCents( r.rate ),
    }
}

export function emitSubscriberInfo(s: SubscriberInfo): SubscriberInfoApi {
    return {
        parkerId: s.parkerId,
        name: s.name,
        note: s.note,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber != null ? s.phoneNumber : null,
        quantity: s.quantity,
    }
}

export function emitSubscriptionUpdateReq(s: SubscriptionUpdateReq): SubscriptionUpdateReqApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
    }
}

export function emitSubEntry(s: SubEntry): SubEntryApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: emitInstant( s.createdWhen ),
        plan: emitPlanInfo( s.plan ),
    }
}

export function emitSubEntryForEdit(s: SubEntryForEdit): SubEntryForEditApi {
    return {
        prices: s.prices.map( el => emitPlanPriceInfo( el ) ),
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: emitInstant( s.createdWhen ),
        plan: emitPlanInfo( s.plan ),
    }
}

export function emitAccessPeriodInfo(a: AccessPeriodInfo): AccessPeriodInfoApi {
    return {
        accessPeriodId: a.accessPeriodId,
        subscriptionEntryId: a.subscriptionEntryId,
        start: emitLocalDate( a.start ),
        end: emitLocalDate( a.end ),
        isDelivered: a.isDelivered,
        price: toCents( a.price ),
    }
}


