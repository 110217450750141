import { ReactNode } from "react";
import { Table, TableProps } from "react-bootstrap";

interface SpotSyncTableProps extends TableProps {
    children: ReactNode
}

export function SpotSyncTable( props: SpotSyncTableProps ) {
    const { children, ...rest } = { ...props };
    return <Table {...rest} striped bordered hover>
        {children}
    </Table>;
}