import { ReactNode } from "react";
import { Col, ColProps, Container, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { HeaderText } from "./Controls/HeaderText";
import { ConnectBalances, ConnectNotificationBanner } from "@stripe/react-connect-js";
import { PageTitle } from "./Controls/PageTitle";
import { NivoHourlyBarChart } from "./NivoHourlyBarChart";
import { NivoMonthlyBarChart } from "./NivoMonthlyBarChart";
import { InfoIcon } from "./Controls/InfoIcon";

const reservationInfo = "All transient reservation revenue is shown here.  Sometimes these are referred to as hourly or daily reservations.";
const planInfo        = "A plan is any recurring parking subscription, usually billed monthly.";

export function OperatorDashboard() {
    return <Container>
        <Row>
            <Col>
                <PageTitle>
                    Dashboard
                </PageTitle>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Container className="g-0">
                    <Row>
                        <Col xs="12" md="6">
                            <HeaderText>
                                Reservation Revenue &nbsp;
                                <InfoIcon header="Reservation Revenue"
                                          body={reservationInfo} />
                            </HeaderText>
                            <NivoHourlyBarChart />
                        </Col>
                        <Col xs="12" md="6">
                            <HeaderText>
                                Plan Revenue &nbsp;
                                <InfoIcon header="Plan Revenue"
                                          body={planInfo} />
                            </HeaderText>
                            <NivoMonthlyBarChart />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}