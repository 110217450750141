import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useState, useRef, useEffect, forwardRef, ReactNode, useImperativeHandle } from "react";
import { FieldError } from "react-hook-form";
import { Input } from "./Input";

interface PlaceInputProps {
    className?:    string;
    label:         string;
    explanation?:  string;
    icon?:         ReactNode;
    placeholder?:  string;
    error?:        FieldError | undefined;
    onPlaceSelect: ( place: google.maps.places.PlaceResult | null ) => void;
}

export const PlaceInput = forwardRef<HTMLInputElement, PlaceInputProps>( 
    ( props: PlaceInputProps, ref ) => {
    
        const {
            className,
            label,
            icon,
            error,
            placeholder,
            explanation,
            onPlaceSelect,
            ...rest
        } = { ...props };

        //hooks
        const [compl, setCompl] = useState<google.maps.places.Autocomplete | null>( null );
        const places   = useMapsLibrary( 'places' );
        const inputRef = useRef<HTMLInputElement>();

        useEffect( () => {
            if( !places || !inputRef.current ) {
                return;
            }
            const opts: google.maps.places.AutocompleteOptions = {
                types:                 [],
                componentRestrictions: { country: "us" }, 
                fields:                ['geometry', 'name', 'formatted_address'] };

            setCompl( new places.Autocomplete( inputRef.current, opts ) );
        }, [places] );

        useEffect( () => {
            if( !compl ) {
                return;
            }
            compl.addListener( 'place_changed', () => onPlaceSelect( compl.getPlace() ) );
        }, [onPlaceSelect, compl] );
        
        function setRef( element: HTMLInputElement ) {
            if( typeof ref === "function" ) { ref( element );        }
            else if( ref )                  { ref.current = element; }
            inputRef.current = element;
        }
        
        return <Input {...rest}
                      ref={setRef} 
                      type="text"
                      label={label}
                      explanation={explanation}
                      icon={icon}
                      placeholder={placeholder}
                      className={className}
                      error={error} />
} );