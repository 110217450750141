import { FacilityImage } from "../Data/ApiTypes";
import { facilityImgNew } from "../Data/Common";

export interface FacilityPhotosProps {
    imgs: FacilityImage[];
}

export function FacilityPhotos( props: FacilityPhotosProps ) {
    const imgs = props.imgs;
    return <div style={{ display: "flex",
                         height: "10em" }}>
        {imgs.map( img => 
            <div className="rounded-3">
                <img style={{ height: "100%", 
                        width: "100%", objectFit: "cover",
                     }}
                    src={facilityImgNew( img.storedImageId )} />
            </div>)}
    </div>
}