import { ReactNode, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg, EventInput } from "@fullcalendar/core";
import { Braces, BracesAsterisk } from "react-bootstrap-icons";
import { PageTitle } from "./Controls/PageTitle";

interface Tool {
    name:        string;
    icon:        ReactNode;
    color:       string;
    explanation: string;
}

const tools: Tool[] = [
    { name: "Facility Closed",   icon: <BracesAsterisk />,         color: "black",  
        explanation: "Click and drag on the calendar to set a time when the facility will not be open.  Only parts that overlap with the open schedule will have an effect." },
];

export function FacilityClosuresPage() {
    const [events, setEvents] = useState<EventInput[]>( [] );
    const [evId,   setEvId  ] = useState( 0 );
    const [selectedTool, setSelectedTool] = useState( tools[0] );

    const handleDateSelect = ( selectInfo: DateSelectArg ) => {
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        const newEvent: EventInput = {
            id:     evId.toString(),
            title:  selectedTool.name,
            start:  selectInfo.startStr,
            end:    selectInfo.endStr,
            allDay: selectInfo.allDay,
            color:  selectedTool.color,
        };
        setEvents( [...events, newEvent] );
        calendarApi.addEvent( newEvent );
        setEvId( evId + 1 );
    };

    const handleEventClick = ( clickInfo: EventClickArg ) => {
        if( window.confirm( `Are you sure you want to delete the event '${clickInfo.event.title}'` ) ) {
            clickInfo.event.remove();
            setEvents( events.filter( event => event.id !== clickInfo.event.id ) );
        }
    };

    return <Container className="h-100">
        <Row className="h-100">
            <Col className="text-justify" style={{ display: "grid", gridTemplateRows: "min-content min-content auto"}}>
                <PageTitle>Facility Closures</PageTitle>
                <div>
                    <p className="d-lg-none text-danger alert alert-danger">
                        This page is disabled on mobile devices.
                    </p>
                    <div className="d-none d-lg-block text-secondary py-3">
                        {selectedTool.explanation}
                    </div>
                </div>
                <FullCalendar
                    height={"100%"}
                    plugins={[multiMonthPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    dayHeaderFormat={{ weekday: "short" }}
                    initialView="timeGridWeek"
                    firstDay={1}
                    slotDuration={"01:00:00"}
                    eventMinHeight={1}
                    buttonText={{
                        today: 'Today',
                        month: 'Month',
                        week:  'Week',
                        day:   'Day',
                        list:  'list'
                    }}
                    headerToolbar={{
                        center: "title",
                        start: 'timeGridWeek',
                    }}
                    
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventDragStart={ info => info.el.style.backgroundColor = selectedTool.color }
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    events={events}
                />

            </Col>
        </Row>
    </Container>;
}

