import React, { useState, ChangeEvent } from 'react';

interface FileInput {
    file: File | null;
    altText: string;
    caption: string;
}

interface UploadResult {
    Count?: number;
    Files?: Array<{
        OriginalFileName: string;
        SavedFileName: string;
        ContentType: string;
        Size: number;
        AltText: string;
        Caption: string;
    }>;
    error?: string;
}

export const FacilityExample: React.FC = () => {
    const [files, setFiles] = useState<FileInput[]>( [{ file: null, altText: '', caption: '' }] );
    const [uploadResult, setUploadResult] = useState<UploadResult | null>( null );

    const handleFileChange = ( event: ChangeEvent<HTMLInputElement>, index: number ) => {
        const updatedFiles = [...files];
        updatedFiles[index].file = event.target.files ? event.target.files[0] : null;
        setFiles( updatedFiles );
    };

    const handleAltTextChange = ( event: ChangeEvent<HTMLInputElement>, index: number ) => {
        const updatedFiles = [...files];
        updatedFiles[index].altText = event.target.value;
        setFiles( updatedFiles );
    };

    const handleCaptionChange = ( event: ChangeEvent<HTMLInputElement>, index: number ) => {
        const updatedFiles = [...files];
        updatedFiles[index].caption = event.target.value;
        setFiles( updatedFiles );
    };

    const addFileInput = () => {
        setFiles( [...files, { file: null, altText: '', caption: '' }] );
    };

    const handleSubmit = async () => {
        const formData = new FormData();

        files.forEach( ( fileInput, index ) => {
            if( fileInput.file ) {
                formData.append( `imageUploads[${index}].Image`, fileInput.file );
                formData.append( `imageUploads[${index}].AltText`, fileInput.altText || '' );
                formData.append( `imageUploads[${index}].Caption`, fileInput.caption || '' );
            }
        } );

        try {
            const response = await fetch( 'https://localhost:7063/monitor/facility', {
                method: 'POST',
                body: formData,
            } );

            if( !response.ok ) {
                throw new Error( `HTTP error! status: ${response.status}` );
            }

            const result: UploadResult = await response.json();
            setUploadResult( result );
            console.log( 'Upload successful:', result );
        } catch( error ) {
            console.error( 'Error uploading images:', error );
            setUploadResult( { error: ( error as Error ).message } );
        }
    };

    return (
        <div>
            <h2>Upload Images</h2>
            {files.map( ( fileInput, index ) => (
                <div key={index}>
                    <input
                        type="file"
                        onChange={( event ) => handleFileChange( event, index )}
                    />
                    <input
                        type="text"
                        placeholder="Alt text"
                        value={fileInput.altText}
                        onChange={( event ) => handleAltTextChange( event, index )}
                    />
                    <input
                        type="text"
                        placeholder="Caption"
                        value={fileInput.caption}
                        onChange={( event ) => handleCaptionChange( event, index )}
                    />
                </div>
            ) )}
            <button onClick={addFileInput}>Add another image</button>
            <button onClick={handleSubmit}>Upload Images</button>

            {uploadResult && (
                <div>
                    <h3>Upload Result:</h3>
                    {uploadResult.error ? (
                        <p>Error: {uploadResult.error}</p>
                    ) : (
                        <pre>{JSON.stringify( uploadResult, null, 2 )}</pre>
                    )}
                </div>
            )}
        </div>
    );
};

