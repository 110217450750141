import { ChangeEvent, useRef } from "react";
import { Col, Container, Form, Row, Image, CloseButton } from "react-bootstrap";
import { Input  } from "./Input";
import { Setter } from "../Data/Types";
import React from "react";
import { Button } from "./Button";

interface ImageUploaderProps {
    file?:       File;
    setFile:     Setter<File | undefined>;
    preview?:    string;
    setPreview:  Setter<string | undefined>;
    children:    string;
}

const ImageUploader = ( { children, file, setFile, preview, setPreview }: ImageUploaderProps ) => {
    const inpRef = useRef<HTMLInputElement>(null);

    const handleFileChange = ( e: ChangeEvent<HTMLInputElement> ) => {
        if( e.target.files && e.target.files.length == 1 ) {
            const f = e.target.files[0];
            setFile( f );
            const url = URL.createObjectURL( f );
            setPreview( url );
        }
        inpRef.current!.value = "";
        inpRef.current!.setAttribute( "class", "d-none" );
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        //nothing to do yet
    }

    function removeImage( img: string ): void {
        setPreview( undefined );
        setFile   ( undefined );
    }

    function remove<T>( arr: T[], element: T ): T[] {
        return arr.filter( el => el !== element );
    }

    return (
        <Container fluid className="p-0 w-100">
            <Form onSubmit={handleSubmit}>
                {!preview && <Button onClick={ () => inpRef.current!.click() } className="w-100">
                    {children}
                </Button>}
                <Input
                    label="Choose Facility Images"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    ref={inpRef} 
                    className="d-none" />

                <Row className="mt-3">
                    {preview && <div className="position-relative">
                        <Image src={preview} alt={`preview`} thumbnail />
                        <CloseButton
                            style={{ position: "absolute", top: "3%", right: "3%" }}
                            className="bg-white opacity-100 shadow"
                            onClick={() => removeImage( preview )}
                        />
                    </div>}
                </Row>
            </Form>
        </Container>
    );
};

export default ImageUploader;