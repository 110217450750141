import { Link, useNavigate } from "react-router-dom";
import { ZonedDateTime } from "js-joda";
import { Modal          } from "react-bootstrap";
import { showDate      } from "./Data/Date";
import { authAtom      } from "./Data/Atoms";
import { useAtom } from "jotai";
import { useState } from "react";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { Alphabet, Asterisk, At, PersonFill } from "react-bootstrap-icons";
import { PageTitle } from "./Controls/PageTitle";
import { SmallPage } from "./Controls/SmallPage";
import { HeaderText } from "./Controls/HeaderText";
import { UserPageDeleteAccountModal } from "./UserPageDeleteAccountModal";
import { Api, ChgEmailAddressReq, ChgPassReq, showRole } from "./Data/Api";

interface ChangeNameProps {
    show: boolean;
    setShow: (show: boolean) => void;
}

export function ChangeNameModal({ show, setShow }: ChangeNameProps) {    
    const [name,  setName ] = useState('');

    const handleSubmit = () => {        
        setShow( false );
    };    

    return (
        <Modal centered show={show} onEscapeKeyDown={() => setShow(false)}>
            <Modal.Header>
                <HeaderText>Change Name</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Input
                        type="text"
                        icon={<Alphabet />}
                        label="Enter New Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="text-end d-flex flex-row justify-content-end">
                    <Button className="w-50 m-2" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button className="w-50 m-2" onClick={handleSubmit}>
                        Change
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

interface ChangeEmailModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
}

export function ChangeEmailModal( { show, setShow }: ChangeEmailModalProps ) {
    const [form, setForm] = useState<ChgEmailAddressReq>( {
        currentPassword: '',
        newEmailAddress: '',
    } );
    
    const [error, setError] = useState<string | null>( null );
    const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setForm( { ...form, [e.target.name]: e.target.value } );
    };

    const handleSubmit = () => {
        setError( null );
        Api.changeEmailAddress( form )
            .then( res => {
                if( res.isOk ) {
                    console.log( 'Email changed successfully:', res.value );
                    setShow( false );
                } else {
                    setError( res.error );
                }
            } )
            .catch( err => {
                console.error( 'API call failed:', err );
                setError( 'An unexpected error occurred. Please try again.' );
            } );
    };

    return (
        <Modal centered show={show} onEscapeKeyDown={() => setShow( false )}>
            <Modal.Header>
                <HeaderText>Change Email Address</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Input
                        type="password"
                        icon={<Asterisk />}
                        label="Enter Current Password"
                        name="currentPassword"
                        value={form.currentPassword}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <Input
                        type="email"
                        icon={<At />}
                        label="Enter New Email Address"
                        name="newEmailAddress"
                        value={form.newEmailAddress}
                        onChange={handleChange}
                    />
                </div>
                {error && <div className="text-danger">{error}</div>}
                <div className="text-end d-flex flex-row justify-content-end">
                    <Button className="w-50 m-2" onClick={() => setShow( false )}>
                        Cancel
                    </Button>
                    <Button className="w-50 m-2" onClick={handleSubmit}>
                        Change
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

interface ChangePasswordModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
}

export function ChangePasswordModal( { show, setShow }: ChangePasswordModalProps ) {
    const [form, setForm] = useState<ChgPassReq>( {
        currentPassword: '',
        newPassword: '',
    } );
    const [error, setError] = useState<string | null>( null );
    const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setForm( { ...form, [e.target.name]: e.target.value } );
    };

    const handleSubmit = () => {
        setError( null );
        Api.changePassword( form )
            .then( res => {
                if( res.isOk ) {
                    console.log( 'Password changed successfully:', res.value );
                    setShow( false );
                } else {
                    setError( res.error );
                }
            } )
            .catch( err => {
                console.error( 'API call failed:', err );
                setError( 'An unexpected error occurred. Please try again.' );
            } );
    };

    return (
        <Modal centered show={show} onEscapeKeyDown={() => setShow( false )}>
            <Modal.Header>
                <HeaderText>Change Password</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Input
                        type="password"
                        icon={<Asterisk />}
                        label="Enter Current Password"
                        name="currentPassword"
                        value={form.currentPassword}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <Input
                        type="password"
                        icon={<Asterisk />}
                        label="Enter New Password"
                        name="newPassword"
                        value={form.newPassword}
                        onChange={handleChange}
                    />
                </div>
                {error && <div className="text-danger">{error}</div>}
                <div className="text-end d-flex flex-row justify-content-end">
                    <Button className="w-50 m-2" onClick={() => setShow( false )}>
                        Cancel
                    </Button>
                    <Button className="w-50 m-2" onClick={handleSubmit}>
                        Change
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function UserProfilePage() {
    const nav = useNavigate();
    const [auth, setAuth] = useAtom( authAtom );
    const [showChgEmail, setShowChgEmail] = useState( false );
    const [showChgPass,  setShowChgPass ] = useState( false );
    const [showChgName,  setShowChgName ] = useState( false );
    const [showDel,      setShowDel     ] = useState( false );

    if( !auth.account || !auth.isLoggedIn ) {
        nav( "/" );
        return;
    }

    return <SmallPage>
        <ChangeNameModal 
            show={showChgName} 
            setShow={setShowChgName} />
            
        <ChangeEmailModal
            show={showChgEmail} 
            setShow={setShowChgEmail} />
        
        <ChangePasswordModal 
            show={showChgPass}
            setShow={setShowChgPass} />        

        <UserPageDeleteAccountModal
            show={showDel}
            setShow={setShowDel} />

        <PageTitle>
            Overview
        </PageTitle>
        <div className="column-gap-4" style={{ 
                display:             "grid", 
                gridTemplateColumns: "minmax( max-content, 1fr) 1fr minmax( max-content, 1fr )" }}>
            <div className="grid-col-1 text-secondary">
                Role
            </div>
            <div className="grid-col-1 fw-bold mb-3">
                {showRole( auth.account.role )}
            </div>
            <div className="grid-col-1 text-secondary">
                Name
            </div>
            <div className="grid-col-1 fw-bold">
                {auth.account.name}
            </div>
            <div className="grid-col-3 mb-3">
                <Link onClick={() => setShowChgName( true )} to={""}>Change</Link>
            </div>
            <div className="grid-col-1 text-secondary ">
                Email
            </div>
            <div className="grid-col-1 fw-bold ">
                {auth.account.emailAddress}
            </div>
            <div className="grid-col-3 mb-3">
                <Link onClick={() => setShowChgEmail( true )} to={""}>Change</Link>
            </div>
            <div className="grid-col-1 text-secondary">
                Password
            </div>
            <div className="grid-col-1 fw-bold">
                ***********
            </div>
            <div className="grid-col-3 mb-3">
                <Link onClick={() => setShowChgPass( true )} to={""}>Change</Link>
            </div>
            <div className="grid-col-1 text-secondary">
                Account Created
            </div>
            <div className="grid-col-1 mb-3">
                {showDate( ZonedDateTime.now().toLocalDate() )}
            </div>
        </div>
    </SmallPage>;
}