import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { Select } from "./Controls/Select";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { SmallPage } from "./Controls/SmallPage";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import { Api } from "./Data/Api";
import { useParamIds } from "./Data/Common";
import { InviteParkerReq, Plan } from "./Data/ApiTypes";
import { showMoney } from "./Data/Money";

export function FacilityParkerInvitePage() {
    const nav            = useNavigate();
    const plans          = useLoaderData() as Plan[];
    const { facilityId } = useParamIds();
    const [msg, setMsg]  = useState<string>();

    const { register, handleSubmit, watch, formState: { errors } } = useForm<InviteParkerReq>({
        defaultValues: {
            name:         "",
            emailAddress: "",
            phoneNumber:  "",
            message:      "",
            quantity:     1,
            planId:      plans?.[0]?.planId ?? 0,
            planPriceId: plans?.[0]?.prices?.[0]?.planPriceId ?? 0
        }
    });

    const vals = watch();
    const prices = plans.find(p => p.planId == vals.planId)?.prices ?? [];

    if (plans.length === 0) {
        return <Navigate to={`/facility/${facilityId}`} />;
    }

    const onSubmit = async (data: InviteParkerReq) => {
        const res = await Api.facilityParkerInvite( facilityId, data );
        if( res.isOk ) {
            setMsg( res.value );
            nav(`/facility/${facilityId}/parker`);
            return;
        }
        setMsg(res.error);
    };

    return (
        <SmallPage>
            <PageTitle>Invite Parker</PageTitle>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    type="text"
                    label="Parker Name"
                    {...register("name", { required: "Name is required" })}
                    error={errors.name}
                />

                <Input
                    type="email"
                    label="Email"
                    {...register("emailAddress", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    })}
                    error={errors.emailAddress}
                />

                <Input
                    type="tel"
                    label="Phone Number (Optional)"
                    {...register("phoneNumber")}
                    error={errors.phoneNumber}
                />

                <Select
                    label="Plan"
                    {...register("planId", { required: "Plan is required" })}
                    error={errors.planId}
                >
                    {plans.map(plan => (
                        <option key={plan.planId} value={plan.planId}>
                            {plan.name}
                        </option>
                    ))}
                </Select>

                <Select
                    label="Price"
                    {...register("planPriceId", { required: "Price is required" })}
                    error={errors.planPriceId}
                >
                    {prices.map(price => (
                        <option key={price.planPriceId} value={price.planPriceId}>
                            {showMoney(price.price)}
                        </option>
                    ))}
                </Select>

                <Input
                    type="number"
                    label="Quantity"
                    min="1"
                    {...register("quantity", {
                        required: "Quantity is required",
                        min: { value: 1, message: "Quantity must be at least 1" }
                    })}
                    error={errors.quantity}
                />

                <Input
                    type="text"
                    label="Message"
                    {...register("message", { required: "Message is required" })}
                    error={errors.message}
                />

                <Button type="submit">Send Invite Email</Button>
            </Form>
        </SmallPage>
    );
}
