import { Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";

export function ConnectAccountManagePage() {
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Payouts
                </PageTitle>
                <ConnectAccountManagePage />
            </Col>
        </Row>
    </Container>;
}