//css
import './index.scss'
import './index.css';

//css for ag-grid
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

//react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";

//pages and components
import { AppLoggedIn                       } from './AppLoggedIn';
import { FacilityListPage                  } from "./FacilityListPage";
import { FacilityEditPage                  } from "./FacilityEditPage";
import { AppFacility                       } from "./AppFacility";
import { FacilityPlanListPage              } from "./FacilityPlanListPage";
import { FacilityPlanEditPage              } from "./FacilityPlanEditPage";
import { FacilityRatesPage                 } from "./FacilityRatesPage";
import { FacilityParkerListPage            } from "./FacilityParkerListPage";
import { FacilityOneTimeInvoiceListPage    } from "./FacilityOneTimeInvoiceListPage";
import { FacilitySchedulePage              } from "./FacilitySchedulePage";
import { FacilityRateBuilderDurationPage   } from "./FacilityRatesDurationPage";
import { FacilityRateBuilderFixedPage      } from "./FacilityRatesFixedPage";
import { FacilityRatesCreatePage           } from "./FacilityRatesCreatePage";
import { LogInPage                         } from "./LogInPage";
import { ForgotPasswordPage                } from "./ForgotPasswordPage";
import { AppUser                           } from "./AppUser";
import { UserProfilePage                   } from "./UserProfilePage";
import { UserAdvancedPage                  } from "./UserAdvancedPage";
import { FacilityParkerDetailsPage         } from "./FacilityParkerDetailsPage";
import { FacilityClosuresPage              } from "./FacilityClosuresPage";
import { FacilityInvoiceEditPage           } from "./FacilityOneTimeInvoiceCreatePage";
import { AppFacilityParker                 } from "./AppFacilityParker";
import { FacilityParkerInvitePage          } from "./FacilityParkerInvitePage";
import { FacilityParkerApplicationViewPage } from "./FacilityParkerViewApplication";
import { FacilityParkerOrderListPage    } from "./FacilityParkerOrderListPage";
import { FacilityParkerVehiclesPage         } from "./FacilityParkerVehiclePage";
import { FacilityExample } from "./FacilityExample";
import { Facility, Order, ParkerAgingRecord, ParkerDetails, ParkerListEntry, Plan, PlanDetails, RateProgram, StripeLinkResp, SubEntryForEdit } from "./Data/ApiTypes";
import { loadResult } from "./Data/Result";
import { Api } from "./Data/Api";

import { parseFacility, parseParkerDetails, parseOperator, parseParkerListEntry, parseOrder, parsePayment,
     parseParkerAgingRecord, parseRateProgram, parseStripeLinkResp } from "./Data/ApiParse";

import { App } from "./App";
import { CustomFontSource, loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { getDefaultStore } from "jotai";
import { authAtom } from "./Data/Atoms";
import { ConnectPaymentsListPage } from "./ConnectPaymentsListPage";
import { ConnectAccountManagePage } from "./AccountManagePage";
import { AppOperator } from "./AppOperator";
import { OperatorDashboard } from "./OperatorDashboard";
import { ConnectPayoutsListPage } from "./ConnectPayoutsListPage";
import { FacilityListingPage } from "./FacilityListingPage";
import { FacilityOverviewPage } from "./FacilityOverviewPage";
import { Sandbox } from "./Sandbox";
import { FacilityParkerPaymentListPage } from "./FacilityParkerPaymentListPage";
import { FacilityPlanCreatePage } from "./FacilityPlanCreatePage";
import { FacilityReportAgingPage } from "./FacilityReportAgingPage";
import { paramIds } from "./Data/Common";
import { FacilityPlanDetailsPage } from "./FacilityPlanDetailsPage";
import { FacilityParkerVehicleDetailsPage } from "./FacilityParkerVehicleDetailsPage";
import { FacilitySubscriptionEditPage } from "./FacilitySubscriptionEditPage";
import { identity } from "@fullcalendar/core/internal";

const ldAuth: LoaderFunction = async( { params } ) => {
    return Api.amILoggedIn().then( res => loadResult( res,
        val => val,
        err => redirect( "/login" )
    ) );
}

const ldFacilities: LoaderFunction<Facility[]> = async( { params } ) => {
    return await Api.facilityList()
        .then( res => loadResult( res,
            res => res.map( parseFacility ),
            err => undefined ) );
}

export interface ExtendedFacility extends Facility {
    connectInstance: StripeConnectInstance;
    account:            StripeLinkResp;
}

const ldFacility: LoaderFunction<Facility> = async( { params } ) => {
    const { facilityId } = paramIds( params )

    const facility = await Api.facilityDetails( facilityId )
        .then( res => loadResult( res, parseFacility, err => undefined ) );

    const link = await Api.stripeLink( facilityId )
        .then( res => loadResult( res, parseStripeLinkResp, err => undefined ) );

    const auth = defaultStore.get( authAtom );
    if( !auth.isLoggedIn ) {
        return redirect( "/login" );
    }

    const fetchClientSecret = async () => {
        return loadResult( await Api.stripeInit( facilityId ),
            val => val.clientSecret,
            err => "Err!" );
    };
    debugger;
    const x = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const connectInstance = loadConnectAndInitialize( {
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        fetchClientSecret: fetchClientSecret,
        appearance: {
            variables: {
                fontFamily:                     "silka",
                fontSizeBase:                   "16px",
                spacingUnit:                    "9px",
                buttonBorderRadius:             "16px",
                colorPrimary:                   "#D61F84", //optional appearance param,
                buttonPrimaryColorText:         "#000000",
                buttonPrimaryColorBackground:   "#FFFFFF",
                buttonPrimaryColorBorder:       "#D61F84",
                buttonSecondaryColorBackground: "#FFFFFF",
                buttonSecondaryColorBorder:     "#D61F84",
                buttonSecondaryColorText:       "#000000",
            },
        },
        fonts: [silkaRegular, silkaBold],
    } );
    if( !facility || !link ) {
        return undefined;
    }
    const ret: ExtendedFacility = {
        ...facility,
        connectInstance: connectInstance,
        account:            link
     };
    return ret;
}

const ldAgingReport: LoaderFunction<ParkerAgingRecord[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityAgingReport( facilityId )
        .then( res => loadResult( res,
            res => res.map( parseParkerAgingRecord ),
            err => undefined )
        );
}

const ldPlans: LoaderFunction<Plan[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityPlanList( facilityId )
        .then( res => loadResult( res, identity, err => undefined ) );
}

const ldRate: LoaderFunction<RateProgram> = async( { params } ) => {
    const { facilityId, rateId } = paramIds( params );
    return await Api.facilityRateGet( facilityId, rateId )
        .then( res => loadResult( res, parseRateProgram, err => undefined ) );
}

const ldFacilityParker: LoaderFunction<ParkerDetails> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return loadResult(
        await Api.facilityParkerDetails( facilityId, parkerId ),
        parseParkerDetails,
        err => undefined );
}

const silkaRegular: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-regular-webfont.woff) format('woff')",
    weight: "normal"
}

const silkaBold: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-bold-webfont.woff) format('woff')",
    weight: "bold"
}

const defaultStore = getDefaultStore();
const ldConnectClientSecret: LoaderFunction<StripeConnectInstance> = async( { params } ) => {

}

const ldOperator: LoaderFunction<Facility> = async( { params } ) => {
    return Api.opDetails().then(
        res => loadResult( res, parseOperator, err => undefined ) );
}

const ldFacilityParkers: LoaderFunction<ParkerListEntry[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityParkers( facilityId )
        .then( res => loadResult( res,
            res => res.map( parseParkerListEntry ),
            err => undefined )
    );
}

const ldOrders: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.orderList( facilityId, parkerId )
        .then( res => loadResult( res,
            res => res.map( parseOrder ),
            err => undefined )
    );
}

const ldPayments: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.paymentList( facilityId, parkerId )
        .then( res => loadResult( res,
            val => val.map( parsePayment ),
            err => undefined )
    );
}


const ldPlanDetails: LoaderFunction<PlanDetails> = async( { params } ) => {
    const { facilityId, planId } = paramIds( params )
    return await Api.facilityPlanGet( facilityId, planId )
        .then( res => loadResult( res, identity, err => undefined ) );
}

const ldSubDetails: LoaderFunction<SubEntryForEdit> = async( { params } ) => {
    const { facilityId, subscriptionId } = paramIds( params )
    return await Api.facilitySubscriptionGet( facilityId, subscriptionId )
        .then( res => loadResult( res, identity, err => undefined ) );
}

// const ldVehicles: LoaderFunction<Vehicle[]> =
//     async ( { params } ) => await Api.vehicleList()
//         .then( res => loadResult( res,
//             val => val,
//             notLoggedInHandler ) );

//based on the type of the account we'll default to facilities list with other things
const router = createBrowserRouter( [
    { path: "/sandbox", element: <Sandbox />, },
    { element: <App />, children: [
        { path: "/login",  element: <LogInPage          /> },
        { path: "/forgot", element: <ForgotPasswordPage /> },
    ] },
    { path: "/", element: <App />, loader: ldAuth, children: [
        { element: <AppLoggedIn />, children: [
            { path: "/operator",                          element: <AppOperator />, loader: ldOperator, children: [
                { path: "/operator/",                     element: <OperatorDashboard        />, },
                { path: "/operator/profile",              element: <OperatorDashboard        />, },
                { path: "/operator/account",              element: <ConnectAccountManagePage />, },
            ] },
            { path: "/",                                  element: <FacilityListPage     />, loader: ldFacilities },

            //user stuff
            { path: "/user",                              element: <AppUser />, children: [
                    { path: "/user",                          element: <UserProfilePage         /> },
                    { path: "/user/profile",                  element: <UserProfilePage         /> },
                    { path: "/user/advanced",                 element: <UserAdvancedPage        /> },
                ] },
                { path: "/facility",                         element: <FacilityListPage     /> },
                { path: "/facility/create",                  element: <FacilityEditPage     /> },
                { path: "/facility/example",                 element: <FacilityExample      /> },
                { path: "/facility/:facilityId",             element: <AppFacility          />, loader: ldFacility, id: "facility", children: [
                    { path: "/facility/:facilityId",         element: <FacilityOverviewPage              />, },
                    { path: "overview",                      element: <FacilityOverviewPage             />, },
                    { path: "payments",                      element: <ConnectPaymentsListPage          />, },
                    { path: "payouts",                       element: <ConnectPayoutsListPage           />, },
                    { path: "listing",                       element: <FacilityListingPage               /> },
                    { path: "schedule",                      element: <FacilitySchedulePage              /> },
                    { path: "closure",                       element: <FacilityClosuresPage              /> },

                    { path: "parker/",                       element: <FacilityParkerListPage            />, loader: ldFacilityParkers },
                    { path: "parker/active",                 element: <FacilityParkerListPage            /> },
                    { path: "parker/applied",                element: <FacilityParkerListPage            /> },
                    { path: "parker/invited",                element: <FacilityParkerListPage            /> },
                    { path: "parker/invite",                 element: <FacilityParkerInvitePage          />, loader: ldPlans },
                    { path: "parker/invite/:parkerId",       element: <FacilityParkerApplicationViewPage /> },

                    { path: "plan",                          element: <FacilityPlanListPage              />, loader: ldPlans       },
                    { path: "plan/create",                   element: <FacilityPlanCreatePage            />, loader: ldFacility    },
                    { path: "plan/:planId",                  element: <FacilityPlanDetailsPage           />, loader: ldPlanDetails },
                    { path: "plan/:planId/edit",             element: <FacilityPlanEditPage              />, loader: ldPlanDetails },

                    { path: "rate",                          element: <FacilityRatesPage                 />, loader: ldFacility },
                    { path: "rate/:rateId",                  element: <FacilityRateBuilderDurationPage   />, loader: ldRate },

                    { path: "rate/create",                   element: <FacilityRatesCreatePage           /> },
                    { path: "rate/create/fixed",             element: <FacilityRateBuilderFixedPage      /> },
                    { path: "rate/create/duration",          element: <FacilityRateBuilderDurationPage   /> },

                    //reports
                    { path: "report/aging",                  element: <FacilityReportAgingPage           />, loader: ldAgingReport },

                    //invoice
                    { path: "invoice",                       element: <FacilityOneTimeInvoiceListPage    /> },
                    { path: "invoice/create",                element: <FacilityInvoiceEditPage           /> },
                ] },
                { path: "/facility/:facilityId/parker/:parkerId",     element: <AppFacilityParker     />, loader: ldFacilityParker, children: [
                    { path: "/facility/:facilityId/parker/:parkerId", element: <FacilityParkerDetailsPage  />, loader: ldFacilityParker },
                    { path: "overview",                      element: <FacilityParkerDetailsPage           />, loader: ldFacilityParker },
                    { path: "order",                         element: <FacilityParkerOrderListPage         />, loader: ldOrders },
                    { path: "plan/:subscriptionId",          element: <FacilitySubscriptionEditPage        />, loader: ldSubDetails },
                 // { path: "order/:orderId",                element: <FacilityParkerOrderDetailsPage      />, loader: ldOrderDetails },
                    { path: "payment",                       element: <FacilityParkerPaymentListPage       />, loader: ldPayments },
                    { path: "vehicle",                       element: <FacilityParkerVehiclesPage          />, loader: undefined },
                    { path: "vehicle/:vehicleId",            element: <FacilityParkerVehicleDetailsPage    />, loader: undefined }
                ] }
        ] }
    ]
} ] );

const root = ReactDOM.createRoot(
    document.getElementById( 'root' ) as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
