import { emitSubscriptionUpdateReq } from "./ApiEmit";
import { parseInviteParkerReq, parseParkerInfo, parsePlan, parsePlanDetails, parseSubEntry, parseSubEntryForEdit } from "./ApiParse";
import {
    CheckCreateReqApi,
    FacilityApi,
    FacilityCreateReqApi,
    FacilityUpdateReqApi,
    InviteParkerReqApi,
    InvoiceApi,
    OperatorApi,
    OrderApi,
    OrderDebtApi,
    OrderDetailsApi,
    ParkerAgingRecordApi,
    ParkerDetailsApi,
    ParkerListEntryApi,
    PaymentApi,
    PlanApi,
    PlanCreateReqApi,
    PlanDetailsApi,
    PlanPriceAddReqApi,
    PlanPriceListRespApi,
    PlanUpdateReqApi,
    RateProgramApi,
    RateProgramDataApi,
    SearchResultApi,
    StripeLinkRespApi,
    SubEntryApi,
    SubscriptionEntryApi,
    SubscriptionUpdateReqApi,
} from "./ApiTransport";
import { SubEntry, SubscriptionUpdateReq } from "./ApiTypes";
import { ApiUrl } from "./ApiUrl";
import { Result } from "./Result";

export const notSet = ( a: any ) => a === null;


export interface LogInReq {
    emailAddress: string;
    password:     string;
}

export interface SignUpReq {
    emailAddress:    string;
    name:            string;
    password:        string;
    passwordConfirm: string;
}

export interface ChgPassReq {
    currentPassword: string;
    newPassword:     string;
}

export interface ChgEmailAddressReq {
    currentPassword: string;
    newEmailAddress: string;
}

export interface SignUpResp {
    staffId:      number;
    operatorId:   number;
    role:         number;
    name:         string;
    emailAddress: string;
}

export interface ConnectInitResp {
    clientSecret: string;
}

export type LogInResp    = SignUpResp;
export type ChgPassResp  = SignUpResp;
export type ChgEmailResp = SignUpResp;

export interface ForgotPassReq  { emailAddress: string; }
export type ForgotPassResp = string;

export const showRole = ( r: number ) => {
    if( r === 0 ) { return "Site Admin"; }
    if( r === 1 ) { return "Operator Admin"; }
    return "Err!";
}

const parseArray = <TValApi, TVal>( func: ( api: TValApi ) => TVal ) => ( x: TValApi[] ) => x.map( func );

export class Api {
    private static getArgs(): RequestInit {
        return {
            method:      "GET",
            credentials: "include"
        }
    }

    private static jsonArgs( verb: string, payload: any ): RequestInit {
        return {
            method:      verb,
            headers:     { "Content-Type": "application/json" },
            body:        JSON.stringify( payload ),
            credentials: "include"
        }
    }

    private static postArgs = ( payload: any ) => Api.jsonArgs( "POST", payload );

    static api<TVal>( url: string, init: RequestInit = {} ): Promise<Result<string, TVal>> {
        return fetch( url, init )
            .then( response => {
                const json = response.json();
                const isOk = ( response.status == 200 );
                return json.then( data => {
                    if( isOk ) {
                        return { isOk: true, value: data };
                    }
                    return { isOk: false, error: data }
                } );
            } );
    }

    static api2<TValApi, TVal>( url: string, init: RequestInit = {}, f: (x: TValApi) => TVal ): Promise<Result<string, TVal>> {
        return fetch( url, init )
            .then( response => {
                const json = response.json();
                const isOk = (response.status == 200);
                return json.then( data => {
                    if( isOk ) {
                        const parsed = f( data );
                        return { isOk: true, value: parsed };
                    }
                    return { isOk: false, error: data }
                } );
            } );
    }

    //auth stuff
    static logIn              = ( req: LogInReq             ) => this.api<LogInResp>     ( ApiUrl.logIn(),               this.postArgs( req ) );
    static logOut             = (                           ) => this.api<LogInResp>     ( ApiUrl.logOut(),              this.postArgs( {}  ) );
    static changePassword     = ( req: ChgPassReq           ) => this.api<ChgPassResp>   ( ApiUrl.changePassword(),      this.postArgs( req ) );
    static changeEmailAddress = ( req: ChgEmailAddressReq   ) => this.api<ChgEmailResp>  ( ApiUrl.changeEmailAddress(),  this.postArgs( req ) );
    static forgotPassword     = ( req: ForgotPassReq        ) => this.api<ForgotPassResp>( ApiUrl.forgotPassword(),      this.postArgs( req ) );
    static amILoggedIn        = (                           ) => this.api<LogInResp>     ( ApiUrl.amILoggedIn(),         this.getArgs()       );

    //facilities
    static facilityCreate     = ( req: FacilityCreateReqApi ) => this.api<string>        ( ApiUrl.facilityCreate(                ), this.postArgs( req ) );
    static facilityUpdate     = ( req: FacilityUpdateReqApi ) => this.api<string>        ( ApiUrl.facilityUpdate( req.facilityId ), this.postArgs( req ) );
    static facilityList       = (                           ) => this.api<FacilityApi[]> ( ApiUrl.facilityList(                  ), this.getArgs() );
    static facilityDetails    = ( facilityId: number        ) => this.api<FacilityApi>   ( ApiUrl.facilityDetails( facilityId    ), this.getArgs() );

    static facilitySearch = ( facilityId: number, query: string ) =>
        this.api<SearchResultApi[]>( ApiUrl.facilitySearch( facilityId, query ), this.getArgs() );

    static facilityParkers( facilityId: number ) {
        return this.api<ParkerListEntryApi[]>( ApiUrl.facilityParkers( facilityId ), this.getArgs() ); }

    static facilityParkerDetails( facilityId: number, parkerId: number )
        { return this.api<ParkerDetailsApi>( ApiUrl.facilityParkerDetails( facilityId, parkerId ), this.getArgs() ); }

    static facilityParkerInvite( facilityId: number, req: InviteParkerReqApi )
        { return this.api2<string, string>( ApiUrl.facilityParkerInvite( facilityId ), this.postArgs( req ), x => x ); }

    static facilitySubscriptionGet( facilityId: number, subscriptionId: number )
        { return this.api2( ApiUrl.facilitySubscriptionGet( facilityId, subscriptionId ), this.getArgs(), parseSubEntryForEdit ); }

    static facilitySubscriptionUpdate( facilityId: number, subscriptionId: number, req: SubscriptionUpdateReq )
        { return this.api2( ApiUrl.facilitySubscriptionUpdate( facilityId, subscriptionId ), this.postArgs( emitSubscriptionUpdateReq( req ) ), parseSubEntry ); }

    //plans
    static facilityPlanCreate( facilityId: number, req: PlanCreateReqApi )
        { return this.api2( ApiUrl.facilityPlanCreate( facilityId ), this.postArgs( req ), parsePlan ); }

    static facilityPlanList( id: number )
        { return this.api2( ApiUrl.facilityPlanList( id ), this.getArgs(), parseArray( parsePlan ) ); }

    static facilityPlanGet( facilityId: number, planId: number )
        { return this.api2( ApiUrl.facilityPlanGet( facilityId, planId ), this.getArgs(), parsePlanDetails ); }

    static facilityPlanUpdate( facilityId: number, planId: number, req: PlanUpdateReqApi )
        { return this.api<PlanApi>( ApiUrl.facilityPlanUpdate( facilityId, planId ), this.postArgs( req ) ); }

    //plan prices
    static facilityPlanPriceAdd (facilityId: number, planId: number, req: PlanPriceAddReqApi) { return this.api<PlanPriceListRespApi>(ApiUrl.facilityPlanPriceCreate(facilityId, planId), this.postArgs(req)); }
    static facilityPlanPriceList(facilityId: number, planId: number) { return this.api<PlanPriceListRespApi[]>(ApiUrl.facilityPlanPriceList(facilityId, planId), this.getArgs()); }

    //rates
    static facilityRateList   = ( facilityId: number) => this.api<RateProgramApi[]>(ApiUrl.facilityRateList(facilityId), this.getArgs());
    static facilityRateGet    = ( facilityId: number, rateProgramId: number ) => this.api<RateProgramApi>(ApiUrl.facilityRateGet(facilityId, rateProgramId), this.getArgs());
    static facilityRateCreate = ( facilityId: number,                        req: RateProgramDataApi ) => Api.api<RateProgramApi>( ApiUrl.facilityRateCreate(facilityId),                Api.postArgs(req) );
    static facilityRateUpdate = ( facilityId: number, rateProgramId: number, req: RateProgramDataApi ) => Api.api<RateProgramApi>( ApiUrl.facilityRateUpdate(facilityId, rateProgramId), Api.postArgs(req) );

    //parker
    static parkerDetails( id: number ) { return this.api<ParkerDetailsApi>( ApiUrl.parkerDetails( id ) /* no need to log in */ ); }

    //stripe
    static stripeInit( facilityId: number ) { return this.api<ConnectInitResp>( ApiUrl.stripeInit( facilityId ), this.postArgs( {} ) ); }
    static stripeLink( facilityId: number ) { return this.api<StripeLinkRespApi>( ApiUrl.stripeLink( facilityId ), this.getArgs() ); }

    //operator
    static opDetails() { return this.api<OperatorApi>( ApiUrl.opDetails(), this.getArgs() ); }

    //finance
    static orderDetails  = ( orderId:    number )                   => this.api<OrderDetailsApi>( ApiUrl.orderDetails( orderId ),     this.getArgs()                         );
    static orderList     = ( facilityId: number, parkerId: number ) => this.api<OrderApi[]>     ( ApiUrl.facilityParkerOrderList(     facilityId, parkerId ), this.getArgs() );
    static orderOpenList = ( facilityId: number, parkerId: number ) => this.api<OrderDebtApi[]> ( ApiUrl.facilityParkerOrderOpenList( facilityId, parkerId ), this.getArgs() );
    static invoiceList   = ( facilityId: number, parkerId: number ) => this.api<InvoiceApi[]>   ( ApiUrl.facilityParkerInvoiceList(   facilityId, parkerId ), this.getArgs() );
    static paymentList   = ( facilityId: number, parkerId: number ) => this.api<PaymentApi[]>   ( ApiUrl.facilityParkerPaymentList(   facilityId, parkerId ), this.getArgs() );

    static paymentCheckCreate( facilityId: number, parkerId: number, req: CheckCreateReqApi )
        { return this.api<PaymentApi>( ApiUrl.facilityParkerCheckCreate( facilityId, parkerId ), this.postArgs( req ) ); }

    //reports
    static facilityAgingReport( facilityId: number ) { return this.api<ParkerAgingRecordApi[]>( ApiUrl.facilityReportAging( facilityId ), this.getArgs() ); }
}