import { Form } from "react-bootstrap";
import { Input } from "./Controls/Input";
import { useForm } from "react-hook-form";
import { Money } from "./Data/Money";
import { Select } from "./Controls/Select";
import { PageTitle } from "./Controls/PageTitle";
import { SmallPage } from "./Controls/SmallPage";

interface FixedRate { 
    name:       string;
    duration:   number;
    unitOfTime: "day" | "hour" | "minute";
    amount:     Money;
}

export function FacilityRateBuilderFixedPage() {
    const { register, watch, formState: { errors } } = useForm<FixedRate>( { defaultValues: {} } )
    return <SmallPage>
        <PageTitle>Create Fixed Rate</PageTitle>
        <div>
            <Form>
                <Input type="text"
                    label="Name"
                    explanation="The name of the fixed rate"
                    {...register( "name", { required: true } )} />

                <Input type="number"
                    label="Price"
                    explanation="The price per unit of time"
                    {...register( "amount" )} />

                <Input type="number"
                    label="Duration"
                    explanation="The number of units of time"
                    {...register( "duration", { required: "Duration is required" } )} />

                <Select label="Unit of Time"
                    {...register( "unitOfTime", { required: "Unit of Time is required" } )}>
                    <option value="day">Day</option>
                    <option value="hour">Hour</option>
                    <option value="minute">Minute</option>
                </Select>
            </Form>
        </div>
    </SmallPage>;
}