import { ConnectPayments, ConnectPayouts } from "@stripe/react-connect-js";
import { Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";

export function ConnectPayoutsListPage() {
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Payouts
                </PageTitle>
                <ConnectPayouts />
            </Col>
        </Row>
    </Container>;
}