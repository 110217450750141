import { useState } from "react";
import { ButtonGroup, Button as BsButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLoaderData } from "react-router-dom";
import { Input } from "./Controls/Input";
import { MediumPage } from "./Controls/MediumPage";
import { PageTitle } from "./Controls/PageTitle";
import { Select } from "./Controls/Select";
import { Api } from "./Data/Api";
import { SubscriptionStatus, SubscriptionUpdateReqApi } from "./Data/ApiTransport";
import { useParamIds } from "./Data/Common";
import { showMoney } from "./Data/Money";
import { processResult } from "./Data/Result";
import { emitSubscriptionUpdateReq } from "./Data/ApiEmit";
import { SubEntryForEdit } from "./Data/ApiTypes";
import { parseSubscriptionUpdateReq } from "./Data/ApiParse";
import { Button } from "./Controls/Button";

export function FacilitySubscriptionEditPage() {
    const nav = useNavigate();
    const [loading, setLoading] = useState( false );
    const [msg, setMsg] = useState<string>();
    const { facilityId, parkerId, subscriptionId } = useParamIds();
    const sub = useLoaderData() as SubEntryForEdit;

    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<SubscriptionUpdateReqApi>( {
        defaultValues: { ...emitSubscriptionUpdateReq( sub ) }
    } );

    const onSubmit = async ( data: SubscriptionUpdateReqApi ) => {
        setLoading( true );
        debugger;
        Api.facilitySubscriptionUpdate( facilityId, subscriptionId, parseSubscriptionUpdateReq( data ) ).then(
            res => processResult( res,
                val => {
                    setMsg( "Subscription Updated Successfully" );
                    setLoading( false );
                    nav( `/facility/${facilityId}/parker/${parkerId}/plan/${subscriptionId}` );
                },
                err => { setMsg( err ); }
            )
        );
    };

    interface StatusOption { lbl: string; expl: string; val: SubscriptionStatus; }

    const reject = {
        lbl: "Reject Application",
        expl: "The application will be rejected",
        val: SubscriptionStatus.Rejected
    };

    const accept = {
        lbl: "Accept Application",
        expl: "The application will be accepted, the parker will be sent a contract to fill out via email",
        val: SubscriptionStatus.WaitingForContract
    };

    const undoReject = {
        lbl: "Undo Reject Application",
        expl: "Set to Applied",
        val: SubscriptionStatus.Applied
    };

    const undoAccept = {
        lbl: "Undo Accept Application",
        expl: "Set to Applied",
        val: SubscriptionStatus.Applied
    };

    const acceptContract = {
        lbl: "Accept Contract",
        expl: "Set to Active",
        val: SubscriptionStatus.Active
    };

    const cancel = {
        lbl: "Cancel Plan",
        expl: "The Plan will be cancelled and will not renew",
        val: SubscriptionStatus.Cancelled
    };

    const reactivate = {
        lbl: "Reactivate Plan",
        expl: "Set to Active",
        val: SubscriptionStatus.Active
    };

    function getInstructions( status: SubscriptionStatus ): string {
        if( status == SubscriptionStatus.Applied ) {
            return "Review the application and accept or reject it";
        }
        if( status == SubscriptionStatus.Rejected ) {
            return "Application has been rejected. You can undo this action to review again";
        }
        if( status == SubscriptionStatus.WaitingForContract ) {
            return "Contract has been sent to customer. Once received, you can accept the contract";
        }
        if( status == SubscriptionStatus.Active ) {
            return "Subscription is active and will auto-renew if payment method is present.";
        }
        if( status == SubscriptionStatus.Cancelled ) {
            return "Subscription is cancelled and will not renew.";
        }
        return "Unknown status";
    }

    function filterStatusOptions( status: SubscriptionStatus ): StatusOption[] {
        if( status == SubscriptionStatus.Applied ) {
            return [reject, accept];
        }
        if( status == SubscriptionStatus.Rejected ) {
            return [undoReject];
        }
        if( status == SubscriptionStatus.WaitingForContract ) {
            return [undoAccept, acceptContract];
        }
        if( status == SubscriptionStatus.Active ) {
            return [cancel];
        }
        if( status == SubscriptionStatus.Cancelled ) {
            return [reactivate];
        }
        return [];
    }

    function showStatus( status: SubscriptionStatus ): string {
        if( status === SubscriptionStatus.Applied ) return "Applied";
        if( status === SubscriptionStatus.Rejected ) return "Rejected";
        if( status === SubscriptionStatus.WaitingForContract ) return "Waiting for Contract";
        if( status === SubscriptionStatus.Active ) return "Active";
        if( status === SubscriptionStatus.Cancelled ) return "Cancelled";
        return "Unknown";
    }

    const values = watch();
    const filteredOptions: StatusOption[] = [
        { lbl: "Do Nothing", val: sub.status, expl: "Status will not be changed" },
        ...filterStatusOptions( sub.status )
    ];
    const actionRequired = sub.status == SubscriptionStatus.Applied
                        || sub.status == SubscriptionStatus.WaitingForContract;
    const selectedStatus = filteredOptions.find( x => x.val === values.status );
    return <MediumPage>
        <PageTitle>
            Edit Parker Plan
        </PageTitle>
        <form onSubmit={handleSubmit( onSubmit )}>
            <div>
                <Select
                    label="Plan Status"
                    disabled
                    error={errors.status}
                    value={sub.status}>
                    <option value={0}>Applied</option>
                    <option value={1}>Rejected</option>
                    <option value={2}>Waiting for Contract</option>
                    <option value={3}>Active</option>
                    <option value={4}>Cancelled</option>
                </Select>
            </div>
            <div className={`${actionRequired ? "alert alert-warning" : "alert alert-success"} p-2 text-center`}>
                { actionRequired ? "Action Required: " + getInstructions( sub.status ) : "No action is required at this time" }
            </div>
            <div>
                <ButtonGroup className="w-100 mb-2 flex justify-items-center">
                    {filteredOptions.map( f => <BsButton
                        className={ f.val === getValues( "status" ) ? "bg-primary text-decoration-underline" : "bg-white text-secondary" }
                        onClick={ () => setValue( "status", f.val ) }>
                        {f.lbl}
                    </BsButton> )}
                </ButtonGroup>
                <div className="text-center fw-bolder my-3">
                    {selectedStatus?.expl}
                </div>
            </div>
            <div className="two-col">
                <Select
                    label="Plan Price"
                    selectClassName="text-end"
                    {...register( "planPriceId", {
                        required: "Plan price is required",
                        valueAsNumber: true
                    } )}
                    error={errors.planPriceId}>
                    {sub.prices?.map( price => (
                        <option key={price.planPriceId} value={price.planPriceId}>
                            {showMoney( price.price )}
                        </option>
                    ) )}
                </Select>
                <Input
                    type="number"
                    label="Quantity"
                    inputClassName="text-end"
                    min={1}
                    {...register( "quantity", {
                        required: "Quantity is required",
                        min: { value: 1, message: "Quantity must be at least 1" },
                        valueAsNumber: true
                    } )}
                    error={errors.quantity}
                />
            </div>
            <div className="two-col">
                <Input
                    type="date"
                    label="Start Date"
                    {...register( "start", { required: "Start date is required" } )}
                    error={errors.start}
                />

                <Input
                    type="date"
                    label="End Date"
                    {...register( "end" )}
                    error={errors.end}
                />
            </div>
            <div className="two-col">
                <Select
                    label="Payment"
                    explanation="Whether payment is required immediately or can be deferred to accounts receievable"
                    {...register( "isAccountsReceivable" )}
                    error={errors.isAccountsReceivable}>
                    <option value="false">Require Payment Immediately</option>
                    <option value="true">Defer to Accounts Receivable</option>
                </Select>
                <Select
                    label="Late Fees"
                    explanation="Enable generating late fees after due date"
                    {...register( "isLateFeeEnabled" )}
                    error={errors.isLateFeeEnabled}>
                    <option value="false">Disabled</option>
                    <option value="true">Enabled</option>
                </Select>
            </div>
            <div className="d-flex justify-content-end gap-2">
                <Button type="submit" loading={loading}>
                    Save Changes
                </Button>
            </div>
        </form>
    </MediumPage>;
}