import { Col, Container, Row } from "react-bootstrap";
import { ArrowUpCircle, Building, Calculator, Calendar, Clock, FileText, Folder, GraphUp, People } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation, useRouteLoaderData } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { Operator } from "./Data/ApiTypes";

interface MenuItemProps {
    icon:     React.ReactNode;
    to:       string;
    text:     string;
    default?: boolean;
    active?:  boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const { active = false } = { ...props };
    return <NavLink to={props.to}
                    className={`facility-menu-item ${active ? "my-active" : "" }`}
                    end={props.default}>
        <div className="grid-col-2 align-self-center">
            {props.icon}
        </div>
        <div className="grid-col-4 align-self-center">
            {props.text}
        </div>
    </NavLink>;
}

export function AppOperator() {
    const loc = useLocation();
    const operator = useLoaderData() as Operator;

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/operator` ) ? ["", "d-none"] : ["d-none", ""];
    const activeOverride = loc.pathname === ( `/operator` ) ? true : false;

    return <Container fluid className="fullheight pt-2">
        <Row className="fullheight">
            <Col md={5} lg={5} xl={4} xxl={3}  id="facility-menu" className={`${menuClassName} d-md-grid fullheight scroll-y-auto`}>
                <div className="facility-menu-item fw-bold">
                    <PageTitle className="grid-col-span-5 text-center align-self-center fw-bold">
                        {operator.name}
                    </PageTitle>
                </div>
                <MenuItem active={activeOverride} default icon={<GraphUp />}  to="./profile" text="Dashboard" />
            </Col>
            <Col md={7} lg={7} xl={8} xxl={9} className={`${contentClassName} p-0 m-0 d-md-block fullheight scroll-y-auto`}>
                <div className="d-md-none w-100 p-3 d-flex align-items-center justify-content-center">
                    <NavLink to="">
                        <ArrowUpCircle className="me-1" />Return to Dashboard
                    </NavLink>
                </div>
                <Outlet />
            </Col>
        </Row>
    </Container>;
}