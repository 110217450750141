import { ListGroup    } from 'react-bootstrap';
import { SearchResult } from "../Data/ApiTypes";
import { Input        } from "./Input";

interface FacilityListSearchProps {
    value: string;
    results?: SearchResult[];
    onChange: ( value: string ) => void;
    onSelect?: ( result: SearchResult ) => void;
    placeholder?: string;
}

export const FacilityListSearch = ( {
    value,
    results = [],
    onChange,
    onSelect,
    placeholder = "Search..."
}: FacilityListSearchProps ) => {
    return (
        <div className="position-relative">
            <div className="position-relative">
                <Input
                    type="text"
                    label="Search"
                    value={value}
                    onChange={( e ) => onChange( e.target.value )}
                    placeholder={placeholder}
                />
            </div>
            {value && results.length > 0 && (
                <ListGroup
                    className="position-absolute w-100 shadow-lg border mt-1 rounded-3"
                    style={{ zIndex: 1000, maxHeight: '300px', overflowY: 'auto' }}>
                    {results.map( ( result ) => (
                        <ListGroup.Item
                            key={result.searchResultId}
                            onClick={() => onSelect?.( result )}
                            className="d-flex align-items-center"
                            action>
                            <div className="d-flex align-items-center gap-2">
                                <div>{result.name}</div>
                            </div>
                        </ListGroup.Item>
                    ) )}
                </ListGroup>
            )}
        </div>
    );
};