
const detectServ = ( url: string ) => {
    const loc = url;
    if( loc.includes( "localhost" ) || loc.includes( "local.dev" ) ) {
        return "https://local.dev:7063/monitor";
    }
    //else we are on a dev server
    if( loc.includes( "monitor.dev.spotsync.com" ) ) {
        return 'https://api.dev.spotsync.com/monitor';
    }
    //we are live
    return 'https://api.spotsync.com/monitor';
}
const serv   = detectServ( window.location.href );
const url    = ( tail: string ) => { return `${serv}${tail}`; }
const encode = ( s: string ) => encodeURIComponent( s );

export class ApiUrl {

    //operator
    static opDetails() { return url( `/` ); }

    //staff
    static amILoggedIn()                              { return url( `/auth/`                             ); } //GET
    static logIn()                                    { return url( `/auth/login`                        ); } //POST
    static logOut()                                   { return url( `/auth/logout`                       ); } //POST
    static forgotPassword()                           { return url( `/auth/forgot`                       ); } //POST
    static changeEmailAddress()                       { return url( `/auth/email`                        ); } //POST
    static changePassword()                           { return url( `/auth/password`                     ); } //POST
    static deleteAccount()                            { return url( `/auth/delete`                       ); } //GET (retrieves a random secret)
    static deleteAccountConfirm()                     { return url( `/auth/delete-confirm`               ); } //POST

    //images
    static imageGet( imgId: number ) { return url( `/img/${imgId}` ); } //GET

    //parker vehicle functionality
    static vehicleCreate              = ()                               => url('/vehicle/add')                     //POST
    static vehicleUpdate              = (vehId: number)                  => url(`/vehicle/${vehId}`)                //POST
    static vehicleSearch              = ()                               => url('/vehicle')                         //GET
    static vehicleDetails             = (vehId: number)                  => url(`/vehicle/vehicle/${vehId}`)        //GET
    static vehicleDelete              = (vehId: number)                  => url(`/vehicle/vehicle/${vehId}/delete`) //POST

    //facilities
    static facilityCreate             = ()                               => url( `/facility/add`                                    ); //POST
    static facilityUpdate             = ( facId: number )                => url( `/facility/${facId}`                               ); //POST
    static facilityList               = ()                               => url( `/facility`                                        ); //POST
    static facilitySearch             = ( facId: number, query: string ) => url( `/facility/${facId}/search?query=${encode(query)}`); //GET
    static facilityDetails            = ( facId: number )                => url( `/facility/${facId}`                               ); //GET
    static facilityParkers            = ( facId: number )                => url( `/facility/${facId}/parker`                        ); //GET
    static facilityParkerDetails      = ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}`               ); //GET
    static facilityParkerOrderList    = ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}/order`         ); //GET
    static facilityParkerOrderOpenList= ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}/order/open`    ); //GET
    static facilityParkerInvoiceList  = ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}/invoice`       ); //GET
    static facilityParkerPaymentList  = ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}/payment`       ); //GET
    static facilityParkerCheckCreate  = ( facId: number, parId: number ) => url( `/facility/${facId}/parker/${parId}/payment/check` ); //POST

    //reports
    static facilityReportAging     = ( facId: number ) => url( `/report/facility/${facId}/aging` ); //GET
    static facilityReportTableList = ( facId: number ) => url( `/report/facility/${facId}/table` ); //GET
    static facilityReportQuery     = ( facId: number ) => url( `/report/facility/${facId}/query` ); //POST

    //facility plan
    static facilityPlanList        = ( facId: number )                 => url( `/facility/${facId}/plan` );                 //GET
    static facilityPlanCreate      = ( facId: number )                 => url( `/facility/${facId}/plan` );                 //POST
    static facilityPlanGet         = ( facId: number, planId: number ) => url( `/facility/${facId}/plan/${planId}` );       //GET
    static facilityPlanUpdate      = ( facId: number, planId: number ) => url( `/facility/${facId}/plan/${planId}` );       //POST
    static facilityPlanPriceCreate = ( facId: number, planId: number ) => url( `/facility/${facId}/plan/${planId}/price` ); //POST
    static facilityPlanPriceList   = ( facId: number, planId: number ) => url( `/facility/${facId}/plan/${planId}/price` ); //GET

    //facility subscription
    static facilitySubscriptionGet    = ( facId: number, subId: number ) => url( `/facility/${facId}/subscription/${subId}` ); //GET
    static facilitySubscriptionUpdate = ( facId: number, subId: number ) => url( `/facility/${facId}/subscription/${subId}` ); //POST

    //facility rate
    static facilityRateList   = ( facId: number )                 => url( `/facility/${facId}/rate` );
    static facilityRateCreate = ( facId: number )                 => url( `/facility/${facId}/rate/create` );
    static facilityRateGet    = ( facId: number, progId: number ) => url( `/facility/${facId}/rate/${progId}` );
    static facilityRateUpdate = ( facId: number, progId: number ) => url( `/facility/${facId}/rate/${progId}` );
    static facilityParkerInvite = ( facilityId: number )          => url( `/facility/${facilityId}/` );

    //parker
    static parkerSearch()               { return url( `/parker`                       ); } //POST
    static parkerDetails( id: number )  { return url( `/parker/${id}`                 ); } //GET

    //e-commerce
    static stripeInit( facId: number ) { return url( `/stripe/${facId}/init` ); } //POST
    static stripeLink( facId: number ) { return url( `/stripe/${facId}/link` ); } //POST

    //finance
    static orderDetails( orderId: number ) { return url( `/finance/order/${orderId}` ); }  // GET

    //Footnotes
    //  #1: Why aren't these urls operating at a higher level?  For example, why not subscribeToMonthly plan?
    //      It's because we're using Stripe elements and it seems this is the way they like to do things
    //      We'll still have our stuff such as subscribeToMonthlyPlan but it will usually be the last step (I think)
}