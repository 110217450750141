import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from "react-bootstrap"

export interface ButtonProps extends BootstrapButtonProps {
    loading?:     boolean;
    loadingText?: string;
}

export function Button( props: ButtonProps ) {
    const { loading = false, children, className, loadingText = "Loading...", style, ...rest } = { ...props };
    const newClassName = `rounded-4 border-primary border-2 status-btn text-black ${className}`;
    return <BootstrapButton as="button" variant="body-secondary" disabled={loading} className={newClassName} style={{ ...style, opacity: "1" }} {...rest}>
        {loading ? <>
            <span className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"></span>
            {loadingText}
        </> : children}
    </BootstrapButton>
}

export function SimpleButton( props: ButtonProps ) {
    const { loading = false, children, className, loadingText = "Loading...", style, ...rest } = { ...props };
    const newClassName = `btn border-primary border-2 status-btn text-black ${className}`;
    return <BootstrapButton disabled={loading} className={newClassName} style={{ ...style, opacity: "1" }} {...rest}>
        {loading ? <>
            <span className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"></span>
            {loadingText}
        </> : children}
    </BootstrapButton>
}