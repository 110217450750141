import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

// Generate some sample data
const generateData = () => {
    const data = [];
    for( let i = 1; i <= 12; i++ ) {
        data.push( {
            month: i,
            value: Math.floor( 1000 + Math.random() * 350 )
        } );
    }
    return data;
};

export const NivoMonthlyBarChart = () => {
    const data = generateData();
    return <div style={{ height: "20em" }}>
        <ResponsiveBar
            data={data}
            keys={['value']}
            indexBy="month"
            margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'pink_yellowGreen' }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Month',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Revenue',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        20
                    ]
                ]
            }}
        />
    </div>;
};
