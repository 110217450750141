//third-party
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLoaderData } from "react-router-dom";

//spotsync
import { Api } from "./Data/Api";
import { FacilityInfo, PlanCreateReq } from "./Data/ApiTypes";
import { Input } from "./Controls/Input";
import { MediumPage } from "./Controls/MediumPage";
import { PageTitle } from "./Controls/PageTitle";
import { useParamIds } from "./Data/Common";
import { Button } from "./Controls/Button";
import { processResult } from "./Data/Result";
import { MoneyInput } from "./Controls/MoneyInput";

export function FacilityPlanCreatePage() {
    const { facilityId, planEntryId } = useParamIds();
    const facility = useLoaderData() as FacilityInfo;
    const verb = "Create";
    const { register, handleSubmit, formState: { errors } } = useForm<PlanCreateReq>( {
        defaultValues: {
            facilityId: facility.facilityId,
            name: "",
            accessHours: "",
            description: "",
            dayOfMonthInvoice: 15,
            dayOfMonthDue: 1,
            dayOfMonthLate: 6,
            initialPrice: 150,
         }
    } );

    const onSubmit = async ( data: PlanCreateReq ) => {
        console.log( data );
        Api.facilityPlanCreate( facilityId, data ).then(
            res => processResult( res,
                val => {
                    console.log( val );
                },
                err => {
                    alert( err );
                }
            )
        );
    };

    return <MediumPage>
        <PageTitle>
            {verb} Plan
        </PageTitle>
        <form onSubmit={handleSubmit( onSubmit )}>
            <Input
                type="text"
                label="Name"
                {...register( "name", { required: "Name is required" } )}
                error={errors.name}
            />

            <Input
                type="text"
                label="Description"
                as="textarea"
                rows={3}
                {...register( "description", { required: "Description is required" } )}
                error={errors.description}
            />

            <Input
                type="text"
                label="Access Hours"
                {...register( "accessHours", { required: "Access hours are required" } )}
                error={errors.accessHours}
            />

            <Container fluid className="g-0">
                <Row>
                    <Col className="fw-bold h4">
                        Scheduling
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Invoice Day"
                            explanation="Invoices are generated on this day of the month"
                            required
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthInvoice", { valueAsNumber: true } )}
                            error={errors.dayOfMonthInvoice}
                        />
                    </Col>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Due Day"
                            explanation="The first day they are allowed to park"
                            required
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthDue", { valueAsNumber: true } )}
                            error={errors.dayOfMonthDue}
                        />
                    </Col>
                    <Col xs={4}>
                        <Input
                            type="number"
                            label="Late Fee Day"
                            explanation="The day late fees are accessed"
                            required
                            min={1} max={28}
                            disabled
                            {...register( "dayOfMonthLate", { valueAsNumber: true } )}
                            error={errors.dayOfMonthLate}
                        />
                    </Col>
                </Row>
            </Container>

            {/* <input type="number"
                {...register( "initialPrice", { valueAsNumber: true, onChange: asdf })} /> */}

            <MoneyInput
                label="Initial Price"
                explanation="This Plan will be listed with this price"
                required
                {...register( "initialPrice", { valueAsNumber: true } )}
            />

            <div className="d-flex justify-content-end gap-2">
                <Button type="submit">
                    Save Changes
                </Button>
            </div>
        </form>
    </MediumPage>;
}

function asdf( event: any ): void {
    console.log( event );
}
