import { Popover, OverlayTrigger } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

export interface InfoIconProps {
    header: string;
    body:   string;
}

export function InfoIcon( props: InfoIconProps ) {
    const { header, body } = props;

    const popover = <Popover id="popover-basic">
        <Popover.Header as="h3">
            {header}
        </Popover.Header>
        <Popover.Body>
            {body}
        </Popover.Body>
    </Popover>;

    return <OverlayTrigger overlay={popover}>
        <InfoCircle color="black" />
    </OverlayTrigger>;
}