
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { Hyperlink } from "./Hyperlink";
import { Facility } from "../Data/ApiTypes";
import { facilityImgNew } from "../Data/Common";

export interface FacilityListEntryProps {
    facility:    Facility;
    onClick:     () => void;
    className?:  string;
}

export function FacilityListEntry( props: FacilityListEntryProps ) {
    const nav = useNavigate();
    const facility = props.facility;
    const { className = "" } = { ...props };
    const { name, listImageId } = { ...props.facility };

    function newParkersClick( e: React.MouseEvent<HTMLSpanElement, MouseEvent> ) {
        e.stopPropagation();
        nav( `/facility/${facility.facilityId}/parker` );
        return false;
    }

    return <div className={`facility-result rounded-4 p-4 mb-3 shadow border ${className}`}
                onClick={ () => props.onClick() }>
        <div>
            <img className="facility-result-pic"
                 src={facilityImgNew( listImageId! )}
                 style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
        </div>
        <div className="facility-result-info">
            <div className="grid-col-1">
                <div className="fs-5 fw-bold">
                    {name}
                </div>
                <div className="d-flex align-items-center gap-2" style={{ fontSize: "0.90em" }}>

                </div>
            </div>
            <div className="grid-col-2 position-relative">
                <div className="fs-5 fw-bold">

                </div>
                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }}>
                    {/* Remaining Spaces {available} */}
                </div>
            </div>
            <div className="facility-result-bottom gap-1">
                <div style={{ justifySelf: "center", alignSelf: "center" }} className="">

                </div>
                <div className="w-100">
                    {/* Manage Facility */}
                </div>
            </div>
        </div>
    </div>;
}