import React from "react";
import { SmallPage } from "./Controls/SmallPage";
import { useParamIds } from "./Data/Common";
import { Button } from "./Controls/Button";
import { useAtom } from "jotai";

interface ApplicationItem {
    name:         string;
    filePath:     string;
}

export function FacilityParkerApplicationViewPage() {
    const { facilityId, parkerId } = useParamIds();
    const parker = { name: "Cory", emailAddress: "cory@cory.com", phoneNumber: "334-546-9423" };

    const files: ApplicationItem[] = [
        { name: "Driver's License", filePath: "/example/license.jpg"          },
        { name: "Proof of Address", filePath: "/example/proof-of-address.jpg" },
    ];
    return <SmallPage>
        <div className="facility-parker-application gap-2">
            <div className="grid-col-1">Name</div>
            <div className="grid-col-2">{parker.name}</div>

            <div className="grid-col-1 text-secondary">Email Address:</div>
            <div className="grid-col-2">{parker.emailAddress}</div>

            <div className="grid-col-1 text-secondary">Phone Number:</div>
            <div className="grid-col-2">{parker.phoneNumber}</div>
            {files.map( (f, i) => <React.Fragment key={i}>
                <div className="grid-col-1">{f.name}</div>
                <div className="grid-col-2"><img src={f.filePath} width="100%" /></div>
            </React.Fragment> )}
        </div>
        <div className="pt-2 d-flex gap-2">
            <Button className="border-danger w-50">
                Reject
            </Button>
            <Button className="w-50">
                Accept Application
            </Button>
        </div>
    </SmallPage>
}