export interface OperatorApi {
    operatorId: number;
    name: string;
    address: UsAddressApi;
    emailAddress: string;
    phoneNumber: string;
    website: string;
    topTitle: string;
    topText: string;
    bottomLeftTitle: string;
    bottomLeftText: string;
    bottomRightTitle: string;
    bottomRightText: string;
}

export interface FacilityInfoApi {
    facilityId: number;
    operatorId: number;
    name: string;
    description: string;
    timeZone: string;
    address: UsAddressApi;
    coordinates: CoordinatesApi;
    listImageId: number | null;
    entryInstructions: string;
}

export interface UsAddressApi {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface CoordinatesApi {
    lat: number;
    lng: number;
}

export interface VehicleApi {
    vehicleId: number;
    make: string;
    model: string;
    color: string;
    stateCode: string;
    licensePlateNumber: string;
}

export interface RateProgramApi {
    rateProgramId: number;
    facilityId: number;
    name: string;
    start: string;
    end: string | null;
    inAfter: string | null;
    inBefore: string | null;
    outAfter: string | null;
    outBefore: string | null;
    entries: RateEntryApi[];
}

export interface RateEntryApi {
    rateEntryId: number;
    rateProgramId: number;
    upTo: number;
    rate: number;
}

export interface FacilityImageApi {
    facilityImageId: number;
    storedImageId: number;
    orderId: number;
    caption: string;
    altText: string;
}

export interface ListedPlanApi {
    planId: number;
    planEntryId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    price: number;
}

export interface OrderDetailsApi {
    order: OrderApi;
    balanceChanges: OrderBalanceApi[];
    summary: string;
}

export interface OrderApi {
    orderId: number;
    initiator: OrderInitiator;
    initiatedByStaffId: number | null;
    parkerId: number;
    facilityId: number;
    price: number;
    createdWhen: string;
    processorPaymentIntentKey: string | null;
    type: OrderType;
    facility: FacilityInfoApi;
    submittedWhen: string | null;
    balance: OrderBalanceApi;
    summary: string;
}

export interface OrderBalanceApi {
    orderBalanceId: number;
    type: OrderBalanceType;
    orderId: number;
    createdWhen: string;
    debt: number;
    paid: number;
    balance: number;
    totalDebt: number;
    totalPaid: number;
    reason: string | null;
    paymentId: number | null;
}

export interface InvoiceApi {
    invoiceId: number;
    orderId: number;
    createdWhen: string;
    dueWhen: string;
    openingBalance: number;
    endingBalance: number;
    order: OrderInfoApi;
    facility: FacilityInfoApi;
}

export interface PaymentApi {
    paymentId: number;
    parentPaymentId: number | null;
    facilityId: number;
    facility: FacilityInfoApi;
    parkerId: number;
    type: PaymentType;
    amount: number;
    balance: number;
    remaining: number;
    paymentMethod: string;
    changes: PaymentChangeApi[];
    applications: ApplyPaymentApi[];
    createdWhen: string;
}

export interface ApplyPaymentApi {
    orderBalanceId: number;
    type: OrderBalanceType;
    facilityId: number;
    parkerId: number;
    orderId: number;
    debt: number;
    paid: number;
    balance: number;
    totalDebt: number;
    totalPaid: number;
}

export interface OrderInfoApi {
    orderId: number;
    initiator: OrderInitiator;
    initiatedByStaffId: number | null;
    parkerId: number;
    facilityId: number;
    price: number;
    createdWhen: string;
    processorPaymentIntentKey: string | null;
    orderType: OrderType;
    submittedWhen: string | null;
    balance: OrderBalanceApi;
    summary: string;
}

export interface PaymentChangeApi {
    paymentId: number;
    parentPaymentId: number | null;
    facilityId: number;
    parkerId: number;
    type: PaymentType;
    amount: number;
    balance: number;
    createdWhen: string;
}

export interface OrderDebtApi {
    orderId: number;
    createdWhen: string;
    orderTotal: number;
    paid: number;
    unpaid: number;
}

export interface StripeLinkRespApi {
    isOnboarded: boolean;
    connectLink: string | null;
}

export interface SearchResultApi {
    searchResultId: number;
    type: EntityType;
    id: number;
    name: string;
}

export interface PlanCreateReqApi {
    facilityId: number;
    name: string;
    accessHours: string;
    description: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    initialPrice: number;
    terms: string;
}

export interface PlanUpdateReqApi {
    planEntryId: number;
    name: string;
    description: string;
    accessHours: string;
    planPriceId: number | null;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    note: string;
    terms: string;
}

export interface PlanPriceAddReqApi {
    name: string;
    price: number;
}

export interface PlanPriceListRespApi {
    planPriceId: number;
    name: string;
    price: number;
}

export interface ReservationApi {
    reservationId: number;
    facilityId: number;
    parkerId: number;
    start: string;
    end: string;
    actualStart: string;
    actualEnd: string;
    duration: number;
    rateProgramId: number;
    price: number;
    createdWhen: string;
    facility: FacilityInfoApi;
    rateProgram: RateProgramApi;
}

export interface SubscriptionApi {
    subscriptionId: number;
    start: string;
    currentEntry: SubscriptionEntryApi;
    history: SubscriptionEntryApi[];
    facility: FacilityInfoApi;
    accessPeriods: AccessPeriodInfoApi[];
}

export interface SubscriptionEntryApi {
    subscriptionEntryId: number;
    subscriptionId: number;
    planEntryId: number;
    planPriceId: number;
    quantity: number;
    start: string;
    end: string | null;
    isAccountsReceivable: boolean;
    accessPeriods: AccessPeriodInfoApi[];
    price: number;
    plan: PlanInfoApi;
}

export interface PlanInfoApi {
    planId: number;
    facilityId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
}

export interface FacilityApi {
    facilityId: number;
    name: string;
    description: string;
    timeZone: string;
    address: UsAddressApi;
    coordinates: CoordinatesApi;
    listImageId: number | null;
    entryInstructions: string;
    physicalCapacity: number;
    enforcedCapacity: number;
    images: FacilityImageApi[];
    hourlyRates: RateProgramApi[];
    listedPlans: ListedPlanApi[];
}

export interface MonitorParkerApi {
    parkerId: number;
    operatorId: number;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    note: string;
}

export interface ParkerListEntryApi {
    parkerId: number;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    note: string;
    balance: number;
    totalDebt: number;
    totalPaid: number;
}

export interface ParkerDetailsApi {
    operatorId: number;
    parkerId: number;
    name: string;
    phoneNumber: string;
    note: string;
    emailAddress: string;
    balance: number;
    totalDebt: number;
    totalPaid: number;
    subscriptions: SubscriptionApi[];
    reservations: ReservationApi[];
}

export interface CheckCreateReqApi {
    checkDate: string;
    checkNumber: string;
    checkMemo: string;
    amount: number;
}

export interface PmtApplyApi {
    orderId: number;
    amount: number;
}

export interface PlanApi {
    planId: number;
    planEntryId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    planPriceId: number | null;
    planPrice: PlanPriceInfoApi | null;
    prices: PlanPriceDetailsApi[];
    note: string;
}

export interface PlanDetailsApi {
    planId: number;
    planEntryId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    planPriceId: number | null;
    terms: string;
    planPrice: PlanPriceInfoApi | null;
    prices: PlanPriceDetailsApi[];
    history: PlanChangeApi[];
}

export interface PlanChangeApi {
    planEntryId: number;
    planId: number;
    name: string;
    description: string;
    accessHours: string;
    dayOfMonthInvoice: number;
    dayOfMonthDue: number;
    dayOfMonthLate: number;
    planPriceId: number | null;
    planPrice: PlanPriceInfoApi | null;
    createdWhen: string;
    createdByStaffId: number;
    note: string;
}

export interface PlanPriceInfoApi {
    planPriceId: number;
    planId: number;
    name: string;
    price: number;
    createdByStaffId: number;
    createdWhen: string;
}

export interface PlanPriceDetailsApi {
    planPriceId: number;
    planId: number;
    name: string;
    price: number;
    createdByStaffId: number;
    createdWhen: string;
    subscribers: SubscriberInfoApi[];
}

export interface InviteParkerReqApi {
    name: string;
    emailAddress: string;
    phoneNumber: string | null;
    planId: number;
    planPriceId: number;
    quantity: number;
    message: string;
}

export interface FacilityCreateReqApi {
    name: string;
    address: UsAddressApi;
    description: string;
    instructions: string;
    postedCapacity: number;
    enforcedCapacity: number;
    imageThumb: string;
    bannerImage: string;
}

export interface FacilityUpdateReqApi {
    facilityId: number;
    name: string;
    address: UsAddressApi;
    description: string;
    instructions: string;
    postedCapacity: number;
    enforcedCapacity: number;
}

export interface ParkerAgingRecordApi {
    parker: ParkerInfoApi;
    orderId: number;
    days30: number;
    days60: number;
    days90: number;
}

export interface ParkerInfoApi {
    parkerId: number;
    name: string;
    note: string;
    emailAddress: string;
    phoneNumber: string | null;
}

export interface RateProgramDataApi {
    facilityId: number;
    name: string;
    start: string;
    end: string | null;
    inAfter: string | null;
    inBefore: string | null;
    outAfter: string | null;
    outBefore: string | null;
    entries: RateEntryDataApi[];
}

export interface RateEntryDataApi {
    upTo: number;
    rate: number;
}

export interface SubscriberInfoApi {
    parkerId: number;
    name: string;
    note: string;
    emailAddress: string;
    phoneNumber: string | null;
    quantity: number;
}

export interface SubscriptionUpdateReqApi {
    subscriptionEntryId: number;
    subscriptionId: number;
    planPriceId: number;
    quantity: number;
    status: SubscriptionStatus;
    start: string;
    end: string | null;
    isAccountsReceivable: boolean;
    isLateFeeEnabled: boolean;
}

export interface SubEntryApi {
    subscriptionEntryId: number;
    subscriptionId: number;
    planId: number;
    planEntryId: number;
    planPriceId: number;
    quantity: number;
    status: SubscriptionStatus;
    start: string;
    end: string | null;
    isAccountsReceivable: boolean;
    isLateFeeEnabled: boolean;
    createdWhen: string;
    plan: PlanInfoApi;
}

export interface SubEntryForEditApi {
    prices: PlanPriceInfoApi[];
    subscriptionEntryId: number;
    subscriptionId: number;
    planId: number;
    planEntryId: number;
    planPriceId: number;
    quantity: number;
    status: SubscriptionStatus;
    start: string;
    end: string | null;
    isAccountsReceivable: boolean;
    isLateFeeEnabled: boolean;
    createdWhen: string;
    plan: PlanInfoApi;
}

export interface AccessPeriodInfoApi {
    accessPeriodId: number;
    subscriptionEntryId: number;
    start: string;
    end: string;
    isDelivered: boolean;
    price: number;
}


export enum OrderType {
    Imported = 5,
    Reserve = 10,
    Subscribe = 20,
    ModifySubscription = 30,
    AccessPeriod = 40,
    Custom = 50,
}

export enum PaymentType {
    ImportedPayment = 20,
    CashPayment = 40,
    AchPayment = 60,
    AchFailed = 80,
    CheckPayment = 100,
    CheckBounce = 120,
    WriteCheck = 140,
    ProcessorPayment = 160,
    ProcessorRefund = 180,
    ProcessorDisputeChange = 200,
    Adjustment = 220,
}

export enum OrderInitiator {
    System = 0,
    Parker = 1,
    Staff = 2,
}

export enum OrderBalanceType {
    OrderPlaced = 10,
    OrderFee = 20,
    ApplyPayment = 30,
    UndoApplyPayment = 40,
    RefundPayment = 50,
    OrderAdjustment = 60,
}

export enum EntityType {
    Parker = 0,
    Staff = 1,
    Plan = 2,
    Order = 3,
    Vehicle = 4,
}

export enum SubscriptionStatus {
    Applied = 0,
    Rejected = 1,
    WaitingForContract = 2,
    Active = 3,
    Cancelled = 4,
}


