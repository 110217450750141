
export enum UsState {
    Alabama,
    Alaska,
    Arizona,
    Arkansas,
    California,
    Colorado,
    Connecticut,
    Delaware,
    Florida,
    Georgia,
    Hawaii,
    Idaho,
    Illinois,
    Indiana,
    Iowa,
    Kansas,
    Kentucky,
    Louisiana,
    Maine,
    Maryland,
    Massachusetts,
    Michigan,
    Minnesota,
    Mississippi,
    Missouri,
    Montana,
    Nebraska,
    Nevada,
    NewHampshire,
    NewJersey,
    NewMexico,
    NewYork,
    NorthCarolina,
    NorthDakota,
    Ohio,
    Oklahoma,
    Oregon,
    Pennsylvania,
    RhodeIsland,
    SouthCarolina,
    SouthDakota,
    Tennessee,
    Texas,
    Utah,
    Vermont,
    Virginia,
    Washington,
    WestVirginia,
    Wisconsin,
    Wyoming,
    WashingtonDc,
}

export function getStateEnumFromCode( stateCode: string ): UsState | undefined {
    switch( stateCode.toUpperCase() ) {
        case 'AL': return UsState.Alabama;
        case 'AK': return UsState.Alaska;
        case 'AZ': return UsState.Arizona;
        case 'AR': return UsState.Arkansas;
        case 'CA': return UsState.California;
        case 'CO': return UsState.Colorado;
        case 'CT': return UsState.Connecticut;
        case 'DC': return UsState.WashingtonDc;
        case 'DE': return UsState.Delaware;
        case 'FL': return UsState.Florida;
        case 'GA': return UsState.Georgia;
        case 'HI': return UsState.Hawaii;
        case 'ID': return UsState.Idaho;
        case 'IL': return UsState.Illinois;
        case 'IN': return UsState.Indiana;
        case 'IA': return UsState.Iowa;
        case 'KS': return UsState.Kansas;
        case 'KY': return UsState.Kentucky;
        case 'LA': return UsState.Louisiana;
        case 'ME': return UsState.Maine;
        case 'MD': return UsState.Maryland;
        case 'MA': return UsState.Massachusetts;
        case 'MI': return UsState.Michigan;
        case 'MN': return UsState.Minnesota;
        case 'MS': return UsState.Mississippi;
        case 'MO': return UsState.Missouri;
        case 'MT': return UsState.Montana;
        case 'NE': return UsState.Nebraska;
        case 'NV': return UsState.Nevada;
        case 'NH': return UsState.NewHampshire;
        case 'NJ': return UsState.NewJersey;
        case 'NM': return UsState.NewMexico;
        case 'NY': return UsState.NewYork;
        case 'NC': return UsState.NorthCarolina;
        case 'ND': return UsState.NorthDakota;
        case 'OH': return UsState.Ohio;
        case 'OK': return UsState.Oklahoma;
        case 'OR': return UsState.Oregon;
        case 'PA': return UsState.Pennsylvania;
        case 'RI': return UsState.RhodeIsland;
        case 'SC': return UsState.SouthCarolina;
        case 'SD': return UsState.SouthDakota;
        case 'TN': return UsState.Tennessee;
        case 'TX': return UsState.Texas;
        case 'UT': return UsState.Utah;
        case 'VT': return UsState.Vermont;
        case 'VA': return UsState.Virginia;
        case 'WA': return UsState.Washington;
        case 'WV': return UsState.WestVirginia;
        case 'WI': return UsState.Wisconsin;
        case 'WY': return UsState.Wyoming;
        default: return undefined;
    }
}

export function showStateCode( stateEnum: UsState ): string {
    switch( stateEnum ) {
        case UsState.Alabama:       return 'AL';
        case UsState.Alaska:        return 'AK';
        case UsState.Arizona:       return 'AZ';
        case UsState.Arkansas:      return 'AR';
        case UsState.California:    return 'CA';
        case UsState.Colorado:      return 'CO';
        case UsState.Connecticut:   return 'CT';
        case UsState.WashingtonDc:  return 'DC';
        case UsState.Delaware:      return 'DE';
        case UsState.Florida:       return 'FL';
        case UsState.Georgia:       return 'GA';
        case UsState.Hawaii:        return 'HI';
        case UsState.Idaho:         return 'ID';
        case UsState.Illinois:      return 'IL';
        case UsState.Indiana:       return 'IN';
        case UsState.Iowa:          return 'IA';
        case UsState.Kansas:        return 'KS';
        case UsState.Kentucky:      return 'KY';
        case UsState.Louisiana:     return 'LA';
        case UsState.Maine:         return 'ME';
        case UsState.Maryland:      return 'MD';
        case UsState.Massachusetts: return 'MA';
        case UsState.Michigan:      return 'MI';
        case UsState.Minnesota:     return 'MN';
        case UsState.Mississippi:   return 'MS';
        case UsState.Missouri:      return 'MO';
        case UsState.Montana:       return 'MT';
        case UsState.Nebraska:      return 'NE';
        case UsState.Nevada:        return 'NV';
        case UsState.NewHampshire:  return 'NH';
        case UsState.NewJersey:     return 'NJ';
        case UsState.NewMexico:     return 'NM';
        case UsState.NewYork:       return 'NY';
        case UsState.NorthCarolina: return 'NC';
        case UsState.NorthDakota:   return 'ND';
        case UsState.Ohio:          return 'OH';
        case UsState.Oklahoma:      return 'OK';
        case UsState.Oregon:        return 'OR';
        case UsState.Pennsylvania:  return 'PA';
        case UsState.RhodeIsland:   return 'RI';
        case UsState.SouthCarolina: return 'SC';
        case UsState.SouthDakota:   return 'SD';
        case UsState.Tennessee:     return 'TN';
        case UsState.Texas:         return 'TX';
        case UsState.Utah:          return 'UT';
        case UsState.Vermont:       return 'VT';
        case UsState.Virginia:      return 'VA';
        case UsState.Washington:    return 'WA';
        case UsState.WestVirginia:  return 'WV';
        case UsState.Wisconsin:     return 'WI';
        case UsState.Wyoming:       return 'WY';
        default:
            return "Invalid US State!";
    }
}

export function showStateName( stateEnum: UsState ): string {
    switch( stateEnum ) {
        case UsState.Alabama:       return "Alabama";
        case UsState.Alaska:        return "Alaska";
        case UsState.Arizona:       return "Arizona";
        case UsState.Arkansas:      return "Arkansas";
        case UsState.California:    return "California";
        case UsState.Colorado:      return "Colorado";
        case UsState.Connecticut:   return "Connecticut";
        case UsState.WashingtonDc:  return "Washington DC";
        case UsState.Delaware:      return "Delaware";
        case UsState.Florida:       return "Florida";
        case UsState.Georgia:       return "Georgia";
        case UsState.Hawaii:        return "Hawaii";
        case UsState.Idaho:         return "Idaho";
        case UsState.Illinois:      return "Illinois";
        case UsState.Indiana:       return "Indiana";
        case UsState.Iowa:          return "Iowa";
        case UsState.Kansas:        return "Kansas";
        case UsState.Kentucky:      return "Kentucky";
        case UsState.Louisiana:     return "Louisiana";
        case UsState.Maine:         return "Maine";
        case UsState.Maryland:      return "Maryland";
        case UsState.Massachusetts: return "Massachusetts";
        case UsState.Michigan:      return "Michigan";
        case UsState.Minnesota:     return "Minnesota";
        case UsState.Mississippi:   return "Mississippi";
        case UsState.Missouri:      return "Missouri";
        case UsState.Montana:       return "Montana";
        case UsState.Nebraska:      return "Nebraska";
        case UsState.Nevada:        return "Nevada";
        case UsState.NewHampshire:  return "New   Hampshire";
        case UsState.NewJersey:     return "New   Jersey";
        case UsState.NewMexico:     return "New   Mexico";
        case UsState.NewYork:       return "New   York";
        case UsState.NorthCarolina: return "North Carolina";
        case UsState.NorthDakota:   return "North Dakota";
        case UsState.Ohio:          return "Ohio";
        case UsState.Oklahoma:      return "Oklahoma";
        case UsState.Oregon:        return "Oregon";
        case UsState.Pennsylvania:  return "Pennsylvania";
        case UsState.RhodeIsland:   return "Rhode Island";
        case UsState.SouthCarolina: return "South Carolina";
        case UsState.SouthDakota:   return "South Dakota";
        case UsState.Tennessee:     return "Tennessee";
        case UsState.Texas:         return "Texas";
        case UsState.Utah:          return "Utah";
        case UsState.Vermont:       return "Vermont";
        case UsState.Virginia:      return "Virginia";
        case UsState.Washington:    return "Washington";
        case UsState.WestVirginia:  return "West  Virginia";
        case UsState.Wisconsin:     return "Wisconsin";
        case UsState.Wyoming:       return "Wyoming";
        default:
            return "Invalid Us State!";
    }
}