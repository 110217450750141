import { Col, Container, Row, Table } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { showDuration } from "./Data/Date";
import { showMoneyShort } from "./Data/Money";
import { Hyperlink } from "./Controls/Hyperlink";
import { PageTitle } from "./Controls/PageTitle";
import { Facility } from "./Data/ApiTypes";
import { rateProgramType } from "./Data/RateProgram";

export function FacilityRatesPage() {

    //params
    const facilityId = useParamIds().facilityId;
    const facility   = useLoaderData() as Facility;
    const nav        = useNavigate();
    return <Container fluid>
        <Row>
            <Col lg="4" className="mb-2">
                <PageTitle>
                    Hourly Rates
                </PageTitle>
                {facility.hourlyRates?.map( prog =>
                <div key={prog.rateProgramId}>
                    <div>
                        <span className="fs-5 me-2">{prog.name}</span>
                        <Hyperlink to={`/facility/${facilityId}/rate/${prog.rateProgramId}`}>
                            Edit
                        </Hyperlink>
                    </div>
                    <Table striped hover bordered className="m-0 table table-compact mb-2">
                        <thead>
                            <tr>
                                <th className="text-center">
                                    { rateProgramType(prog) }
                                </th>
                                <th className="text-center">
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {prog?.entries.map( (rate, i) => ( <tr key={i}>
                                <td className="text-center">
                                    Up to {showDuration( rate.upTo )}
                                </td>
                                <td className="text-center">
                                    {showMoneyShort( rate.rate )}
                                </td>
                            </tr> ))}
                        </tbody>
                    </Table>
                </div> )}
                <div className="mt-2">
                    <Button className="w-100" onClick={ () => nav(`/facility/${facilityId}/rate/create`) }>
                        Create New Rate
                    </Button>
                </div>
            </Col>
        </Row>
    </Container>;
}