import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

export interface SmallPageProps {
    children: ReactNode;
}

export function SmallPage( props: SmallPageProps ) {
    return <Container fluid>
        <Row>
            <Col md={7} lg={6} xl={5} xxl={4}>
                {props.children}
            </Col>
        </Row>
    </Container>;
}