import React, { useMemo } from 'react';
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Order } from "./Data/ApiTypes";
import { useLoaderData } from "react-router-dom";
import { showMoney } from "./Data/Money";
import { showOrderInitiator, showOrderType } from "./Data/Finance";
import { showInstant } from "./Data/Date";

export function FacilityParkerOrderListPage() {
    const orders = useLoaderData() as Order[];

    const defaultColDef = useMemo<ColDef>( () => ( {
        sortable:  true,
        filter:    true,
        resizable: true,
    } ), [] );

    const orderColumns = useMemo<ColDef<Order>[]>( () => [
        { field: 'orderId',                   headerName: 'Order Id',       type: 'rightAligned' },
        { field: 'type',                      headerName: 'Order Type',     valueFormatter: ps => showOrderType( ps.value ) },
        { field: 'summary',                   headerName: 'Summary',        valueFormatter: ps => showOrderType( ps.value ) },
        { field: 'createdWhen',               headerName: 'Created Date',   hide: true, valueFormatter: ps => showInstant( ps.value, ps.data?.facility.timeZone! ) },
        { field: 'initiatedByStaffId',        headerName: 'Staff Id',       type: 'rightAligned', hide: true },
        { field: 'parkerId',                  headerName: 'Parker Id',      type: 'rightAligned', hide: true },
        { field: 'facilityId',                headerName: 'Facility Id',    type: 'rightAligned', hide: true },
        { field: 'price',                     headerName: 'Price',          type: 'rightAligned', valueFormatter: ps => ps.value ? showMoney( ps.value ) : "" },
        { field: 'processorPaymentIntentKey', headerName: 'Payment Intent', hide: true },
        { field: 'balance.balance',           headerName: 'Balance',        type: 'rightAligned', valueGetter: ps => showMoney( ps.data?.balance?.balance! ) },
        { field: 'initiator',                 headerName: 'Created By',     hide: true, valueFormatter: ps => showOrderInitiator( ps.value ) },
    ], [] );

    const paymentColumns = useMemo<ColDef[]>( () => [
        { field: 'type',        headerName: 'Type' },
        { field: 'paymentId',   headerName: 'Payment #',   type: 'rightAligned' },
        { field: 'amount',      headerName: 'Amount',      type: 'rightAligned' },
        { field: 'createdWhen', headerName: 'Created Date' },
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="orders"
                        id="parker-details-tabs"
                        className="mb-3 mt-4"
                        variant="underline"
                        justify>
                        <Tab eventKey="orders" title="Orders">
                            <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                                <AgGridReact
                                    rowData={orders}
                                    columnDefs={orderColumns}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    domLayout='autoHeight'
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}